import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  OnChanges,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { splitAtColon } from "@angular/compiler/src/util";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, DateAdapter } from "@angular/material";
import { DataTableFormComponent } from "../data-table-form/data-table-form.component";
import { TableService } from "src/app/core/data/table.service";
import { DeleteConfirmDialog } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { first } from "rxjs/operators";
import { trigger, transition, query, style, stagger, animate } from "@angular/animations";
import { formatDate } from "src/app/shared/helpers/convertDate";
import { DataService } from "src/app/shared/services/data.service";
import { downloadCSV } from "src/app/shared/helpers/downloadCSV";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
  animations: [
    trigger("EnterLeave", [
      transition("void => *", [
        query(".child", style({ transform: "translateY(100%)", opacity: 0 })),
        query(
          ".child",
          stagger("50ms", [animate("50ms .1s ease-out", style({ transform: "translateY(0)", opacity: 1 }))])
        ),
      ]),
      transition("* => void", [
        query(".child", style({ transform: "translateY(100%)", opacity: 1 })),
        query(
          ".child",
          stagger("-50ms", [animate("100ms .1s ease-out", style({ transform: "translateY(0)", opacity: 0.2 }))])
        ),
      ]),
    ]),
    trigger("pageAnimations", [
      transition(":enter", [
        query(".child", [
          style({ opacity: 0, transform: "translateY(100%)" }),
          stagger(-30, [animate("200ms cubic-bezier(0.35, 0, 0.25, 1)", style({ opacity: 1, transform: "none" }))]),
        ]),
      ]),
    ]),
    trigger("filterAnimation", [
      transition(":enter, * => 0, * => -1", []),
      transition(":increment", [
        query(
          ":enter",
          [
            style({ opacity: 0, width: "0px" }),
            stagger(50, [animate("300ms ease-out", style({ opacity: 1, width: "*" }))]),
          ],
          { optional: true }
        ),
      ]),
      transition(":decrement", [
        query(":leave", [stagger(50, [animate("300ms ease-out", style({ opacity: 0, width: "0px" }))])]),
      ]),
    ]),
    /*trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0)',opacity:'1' })),
      transition(':enter', [
        style({ transform: 'translateY(100%)',opacity:'0' }),
        animate('0.2s 200ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translateY(100%)' }))
      ])
    ])*/
  ],
})
export class DataTableComponent implements OnChanges {
  @Input() title = "";
  @Input() tableArray;
  @Input() columns;
  @Input() hasDate = false;
  @Input() canUpdate = false;
  @Input() canCreate = false;
  @Input() canDelete = false;
  @Input() isUrlAction = false;
  @Input() isEmitAction = false;
  @Input() urlAction = "";
  @Input() hideSearch = false;
  @Input() isReorderActive = false;
  @Output() sendData = new EventEmitter();
  @Output() filterByDate = new EventEmitter();
  @Output() emitAction = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("download", null) downloadLink: ElementRef;
  @ViewChild(MatTable, null) private _table: MatTable<any>;

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  oldData = "";
  editing: boolean = false;
  startDate = new Date(new Date().setDate(new Date().getDate() - 14));
  endDate = new Date();
  searchQuery = "";

  constructor(
    private renderer: Renderer2,
    private translate: TranslateService,
    private dialog: MatDialog,
    private tableService: TableService,
    private _adapter: DateAdapter<any>,
    public dataService: DataService
  ) {}

  ngOnInit() {
    if (this.hasDate) {
      this._adapter.setLocale(this.translate.currentLang);
      this.translate.onLangChange.subscribe((lng) => {
        this._adapter.setLocale(lng.lang);
      });
    }
  }

  async ngOnChanges() {
    if (!this.tableArray) {
      this.dataSource = null;
    }
    if (this.tableArray) {
      this.displayedColumns = []; //to avoid duplication ID's problem in table
      this.searchQuery = "";
      //get colums and create display colums
      this.columns.forEach((data, index) => {
        this.displayedColumns.push(data.id);
      });
      //console.log(this.tableArray)
      //check if user has roles for create, update & delete
      if (this.canUpdate == true || this.canCreate == true || this.canDelete == true) {
        this.displayedColumns = [...this.displayedColumns, "actions"];
      } else {
        this.displayedColumns = this.displayedColumns;
      }
      //add editing type to table
      this.tableArray.forEach((data, index) => {
        this.tableArray[index]["editing"] = false;
      });
      //get data and put it in table datasource
      //this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
      this.dataSource = new MatTableDataSource(this.tableArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  searchDate() {
    this.filterByDate.emit({ startDate: this.startDate, endDate: this.endDate, searchQuery: this.searchQuery });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async editRow(rowData) {
    //this.dataSource.data[rowIndex]['editing'] = true;
    //this.editing = true;
    const dialogRef = this.dialog.open(DataTableFormComponent, {
      //width: '250px',
      data: { data: rowData, displayData: this.columns },
      disableClose: true,
      direction: this.translate.currentLang == "ar" ? "rtl" : "ltr",
    });

    try {
      let finalData = await dialogRef.afterClosed().pipe(first()).toPromise();
      if (finalData) {
        this.sendData.emit(finalData);
      }
    } catch (error) {
      alert(error.error.error.message);
    }
  }

  async deleteRow(rowData) {
    //console.log(rowData)
    const dialogRef = this.dialog.open(DeleteConfirmDialog, {
      width: "250px",
      data: { password: "" },
      disableClose: true,
    });

    try {
      let confirmDelete = await dialogRef.afterClosed().pipe(first()).toPromise();

      if (confirmDelete) {
        await this.tableService.Remove(rowData).pipe(first()).toPromise();
        const rowIndex = this.dataSource.data.findIndex((data) => data.id == rowData.id);
        if (rowIndex != -1) {
          this.dataSource.data.splice(rowIndex, 1);
        }
        this.dataSource._updateChangeSubscription();
      }
    } catch (error) {
      alert(error.error.error.message);
    }
  }

  editDone(rowIndex) {
    /*let prepareData = this.dataSource.data[rowIndex];
    delete prepareData['editing'];
    Object.keys(prepareData).forEach(element => {
      //console.log(element)
      const searchInData = this.columns.findIndex(data => data.id == element)
      if(searchInData == -1){
        delete prepareData[element];
      }
    });
    //convert data to thier real type
    this.columns.forEach((each,index) => {
      if(each.type == 'number'){
        prepareData[each.id] = Number(prepareData[each.id]);
      }
    });

    this.sendData.emit(prepareData);
    this.oldData = '';
    this.dataSource.data[rowIndex]['editing'] = false;
    this.editing = false;*/
  }

  editCancel(rowIndex) {
    if (this.dataSource.data[rowIndex]["id"] != 0) {
      this.oldData = "";
      this.dataSource.data[rowIndex]["editing"] = false;
      this.editing = false;
    } else {
      this.editing = false;
      this.dataSource.data.splice(rowIndex, 1);
    }
    //console.log(this.dataSource.data)
  }

  async addRow() {
    let newData = {};
    this.columns.forEach((data) => {
      if (data.type == "number") {
        newData[data.id] = 0;
      } else if (data.type == "boolean") {
        newData[data.id] = false;
      } else if (data.type == "booleanNumber") {
        newData[data.id] = 0;
      } else {
        newData[data.id] = "";
      }
    });
    const dialogRef = this.dialog.open(DataTableFormComponent, {
      //width: '250px',
      data: { data: newData, displayData: this.columns },
      disableClose: true,
      direction: this.translate.currentLang == "ar" ? "rtl" : "ltr",
    });

    try {
      let finalData = await dialogRef.afterClosed().pipe(first()).toPromise();
      if (finalData) {
        this.sendData.emit(finalData);
      }
    } catch (error) {
      alert(error.error.error.message);
    }
    /*delete newData['actions'];
    newData['editing'] = true;
    this.editing = true;
    this.tableArray.unshift(newData);
    this.dataSource = new MatTableDataSource(this.tableArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;*/
  }

  checkBooleanType(columnType, index, fieldName) {
    if (this.dataSource.data[index][fieldName] == true) {
      if (columnType == "booleanNumber") {
        this.dataSource.data[index][fieldName] = 1;
      }
    } else if (this.dataSource.data[index][fieldName] == false) {
      if (columnType == "booleanNumber") {
        this.dataSource.data[index][fieldName] = 0;
      }
    }
  }

  async downloadData() {
    const columns = await this.translateColumns(); //lets get translated header
    //const findActions = columns.findIndex(data => data == 'actions');
    //if(findActions != -1) columns.splice(findActions,1);
    let csv = columns.join() + "\n";
    //csv += this.dataSource.data.map(data => this.dataToCSV(data)).join("\n");//this is for all of data
    csv += this.dataSource.filteredData.map((data) => this.dataToCSV(data)).join("\n"); //this is for filtered data
    //this.renderer.setAttribute(this.downloadLink.nativeElement, "href", "data:text/csv;charset=utf-8,"+encodeURIComponent(csv));
    downloadCSV("sadanData", csv);
  }

  dataToCSV(team) {
    const columns = this.displayedColumns;
    let finalData = [];
    columns.forEach((data) => {
      finalData.push(team[data]);
    });
    return finalData;
  }

  async translateColumns(): Promise<any[]> {
    //get translate data
    const trnaslate = await this.translate.translations;
    const currentLang = this.translate.currentLang;
    let translateData = [];
    this.columns.forEach((data) => {
      //llets loop on incoming data to get title and translate it
      translateData.push(trnaslate[currentLang]["DataTable"][data.title]);
    });
    return translateData;
  }

  formatDate(date) {
    return formatDate(date);
  }

  async reorderRows(event: CdkDragDrop<any>) {
    const { previousIndex, currentIndex } = event;
    // console.log(previousIndex, currentIndex);

    if (previousIndex === currentIndex) return;

    console.log(event);
    const movedRow = event.previousContainer.data[previousIndex];

    try {
      await this.tableService
        .Reorder({
          itemId: movedRow.id,
          newPosition: currentIndex,
        })
        .toPromise();

      // TODO: enhance the workaround of rerender the new data
      const data = JSON.parse(JSON.stringify(this.dataSource.data));

      moveItemInArray(data, previousIndex, currentIndex);

      this.dataSource.data = data;

      this._table.renderRows();
    } catch (error) {
      console.error(error);
    }
  }
}
