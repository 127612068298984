import { Component, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { TableService } from "src/app/core/data/table.service";
import { AuthService } from "src/app/core/auth.service";

@Component({
  selector: "app-medical-facilities",
  templateUrl: "./medical-facilities.component.html",
  styleUrls: ["./medical-facilities.component.scss"],
})
export class MedicalFacilitiesComponent {
  isDataLoading: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "medicalFacilities";
  url: string = "medicalFacilities";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name ar",
      id: "medicalFacilityName_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Name en",
      id: "medicalFacilityName_en",
      type: "text",
      editable: true,
    },
    {
      title: "Address Ar",
      id: "medicalFacilityAddress_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Address En",
      id: "medicalFacilityAddress_en",
      type: "text",
      editable: true,
    },
    {
      title: "Details Status",
      id: "medicalFacilityStatus",
      type: "number",
      editable: true,
    },
  ];

  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService, public authService: AuthService) {
    this.tableService._table = "medicalfacilities";
    this.getData();
  }

  getData() {
    this.tableService.getData('{"order":"id desc","where":{"and":[{"placeTypeId":5}]}}').subscribe((data: any) => {
      this.dataSource = data;
    });
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
