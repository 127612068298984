import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { DataService } from "../services/data.service";

@Component({
  selector: "image-edit",
  templateUrl: "./image-edit.component.html",
  styleUrls: ["./image-edit.component.scss"],
})
export class ImageEdit {
  readonly MAXIMUM_IMAGE_SIZE = 1000000;
  isImageInvalid = false;

  imageChangedEvent: any = "";
  croppedImage: any = "";
  imageRatio = 1 / 1;

  constructor(
    public dialogRef: MatDialogRef<ImageEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService
  ) {
    this.croppedImage = data.imageUrl;
    //console.log(data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (this.data && this.data.imageRatio) this.imageRatio = this.data.imageRatio;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    const uploadedImg = event.target.files[0];

    // Not allow to upload image which size is equal or more than 2MB
    this.isImageInvalid = uploadedImg.size >= this.MAXIMUM_IMAGE_SIZE;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.dataService.notification("Please select image.");
    // show message
  }
}
