export enum roles {
  admin = "admin",
  superAdmin = "superAdmin",
  user = "user",
  company = "company",
  delivery = "delivery",
}

export enum rolesId {
  superAdminRole = 1,
  adminRole = 2,
  userRole = 3,
  companyRole = 4,
  deliveryRole = 5,
}

export enum usersTypes {
  adminType = 1,
  userType = 2,
  deliveryType = 3,
}
