import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "src/app/core/auth.service";

@Component({
  selector: "app-graves-list",
  templateUrl: "./graves-list.component.html",
  styleUrls: ["./graves-list.component.scss"],
})
export class gravesListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "graves";
  url: string = "graves";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name ar",
      id: "graveName_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Name en",
      id: "graveName_en",
      type: "text",
      editable: true,
    },
    /*{
      title:'Address Ar',
      id:'graveAddress_ar',
      type:'text',
      editable:true,
    },
    {
      title:'Address En',
      id:'graveAddress_en',
      type:'text',
      editable:true,
    },
    {
      title:'Guard Name',
      id:'graveGuardName',
      type:'text',
      editable:true,
    },
    {
      title:'Guard Phone',
      id:'graveGuardPhone',
      type:'text',
      editable:true,
    },*/
    {
      title: "City",
      id: "graveCityId",
      type: "text",
      editable: true,
    },
    {
      title: "Boxes Per Day",
      id: "graveBoxesPerDay",
      type: "number",
      editable: true,
    },
    {
      title: "Max Boxes",
      id: "graveBoxesMax",
      type: "number",
      editable: true,
    },
    {
      title: "Min Boxes",
      id: "graveBoxesMin",
      type: "number",
      editable: true,
    },
    {
      title: "Current Boxes",
      id: "graveCurrentBoxes",
      type: "number",
      editable: true,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService, public authService: AuthService) {
    this.tableService._table = "graves";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  getData() {
    this.tableService.getData('{"order":"id desc"}').subscribe((data: any) => {
      this.dataSource = data;
    });
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
