import { AbstractControl } from "@angular/forms";

export function addControlError(control: AbstractControl, error: string) {
  control.setErrors({ [error]: true });
}

export function removeControlErrorOnUpdate(control: AbstractControl, error: string) {
  if (control.errors && control.hasError(error)) {
    delete control.errors[error];
    control.updateValueAndValidity();
  }
}

export function validateYoutubeLink(control: AbstractControl) {
  if (!isYoutubeLink(control.value)) {
    addControlError(control, "pattern");
  } else {
    removeControlErrorOnUpdate(control, "pattern");
  }
}

export function isYoutubeLink(link: string): boolean {
  const youtubeLinkPattern =
    /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:[^\s&?#]*)?/g;
  const youtubeLinkTest = youtubeLinkPattern.test(link.trim());
  return youtubeLinkTest;
}
