import { NgModule } from "@angular/core";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { CoreRouting } from "./core.routing";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en-GB";
import { AngularFireModule } from "@angular/fire";
import { AngularFireStorageModule } from "@angular/fire/storage";

registerLocaleData(localeEn);

@NgModule({
  imports: [AngularFireModule.initializeApp(environment.firebase), AngularFireStorageModule, CoreRouting],
  declarations: [PageNotFoundComponent],
  providers: [AuthService],
})
export class CoreModule {}
