import { MatPaginatorIntl } from "@angular/material";
import { TranslateParser, TranslateService } from "@ngx-translate/core";

export class MyMatPaginatorIntl extends MatPaginatorIntl {
  private rangeLabelIntl: string;
  ofLabel: string = "Of";

  constructor(private translateService: TranslateService, private translateParser: TranslateParser) {
    super();
    this.transToDefaultLang();
    this.getTranslations();
  }

  getTranslations() {
    this.translateService.onLangChange.subscribe((data) => {
      this.translateService
        .get([
          "DataTable.PAGINATOR.ITEMS_PER_PAGE",
          "DataTable.PAGINATOR.NEXT_PAGE",
          "DataTable.PAGINATOR.PREVIOUS_PAGE",
          "DataTable.PAGINATOR.RANGE",
          "DataTable.PAGINATOR.Of",
        ])
        .subscribe((translation) => {
          this.itemsPerPageLabel = translation["DataTable.PAGINATOR.ITEMS_PER_PAGE"];
          this.nextPageLabel = translation["DataTable.PAGINATOR.NEXT_PAGE"];
          this.previousPageLabel = translation["DataTable.PAGINATOR.PREVIOUS_PAGE"];
          this.rangeLabelIntl = translation["DataTable.PAGINATOR.RANGE"];
          this.ofLabel = translation["DataTable.PAGINATOR.Of"];
          this.changes.next();
        });
    });
  }

  transToDefaultLang() {
    this.translateService
      .get([
        "DataTable.PAGINATOR.ITEMS_PER_PAGE",
        "DataTable.PAGINATOR.NEXT_PAGE",
        "DataTable.PAGINATOR.PREVIOUS_PAGE",
        "DataTable.PAGINATOR.RANGE",
        "DataTable.PAGINATOR.Of",
      ])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation["DataTable.PAGINATOR.ITEMS_PER_PAGE"];
        this.nextPageLabel = translation["DataTable.PAGINATOR.NEXT_PAGE"];
        this.previousPageLabel = translation["DataTable.PAGINATOR.PREVIOUS_PAGE"];
        this.rangeLabelIntl = translation["DataTable.PAGINATOR.RANGE"];
        this.ofLabel = translation["DataTable.PAGINATOR.Of"];
        this.changes.next();
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };
}
