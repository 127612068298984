import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { first, debounceTime, tap, switchMap, finalize, startWith, distinctUntilChanged } from "rxjs/operators";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-places-reports-view",
  templateUrl: "./places-reports-view.component.html",
  styleUrls: ["./places-reports-view.component.scss"],
})
export class placesReportsViewComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  url: string = "reports/places";
  startDate = new Date(new Date().setDate(new Date().getDate() - 1));
  endDate = new Date();
  searchType = "places";
  searchQuery = "";
  dataSource: MatTableDataSource<any>;
  //auto complete
  searchPlacesCtrl = new FormControl();
  filteredPlaces: any = [];
  isLoading = false;
  errorMsg: string;
  //current
  currentPlaceId;
  currentPlaceType;
  displayFn;

  constructor(private tableService: TableService, public dataService: DataService) {
    this.tableService._table = "places";
    //this.getData();
  }

  ngOnInit() {
    this.searchPlacesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        /*tap(() => {
          this.errorMsg = "";
          this.filteredPlaces = [];
          this.isLoading = true;
        }),*/
        switchMap((value) => {
          if (value.length > 3) {
            this.errorMsg = "";
            this.filteredPlaces = [];
            this.isLoading = true;
            return this.prepareDateAndGet(value).pipe(
              finalize(() => {
                this.isLoading = false;
              })
            );
          } else {
            return [];
          }
        })
      )
      .subscribe((data) => {
        if (data.length == 0) this.isLoading = false;
        this.filteredPlaces = data;
      });
  }

  print(event) {
    console.log(event);
  }

  prepareDateAndGet(value) {
    let filter = "";
    if (this.searchType == "places") {
      this.tableService._table = "places";
      filter = `{"limit":30,"where":{"or":[{"placeName_en":{"regexp":"/.*${value}.*/i"}},{"placeName_ar":{"regexp":"/.*${value}.*/i"}}, {"placeAddress_ar": {"regexp":"/.*${value}.*/i"}},{"placeAddress_en": {"regexp":"/.*${value}.*/i"}} ]},"fields":{"id":true,"placeName_en":true,"placeName_ar":true}}`;
    } else if (this.searchType == "graves") {
      this.tableService._table = "graves";
      filter = `{"limit":30,"where":{"or":[{"graveName_en":{"regexp":"/.*${value}.*/i"}},{"graveName_ar":{"regexp":"/.*${value}.*/i"}}, {"graveAddress_ar": {"regexp":"/.*${value}.*/i"}},{"graveAddress_en": {"regexp":"/.*${value}.*/i"}} ]},"fields":{"id":true,"graveName_en":true,"graveName_ar":true}}`;
    }
    return this.tableService.getData(filter);
  }

  changeCurrentPlace(placeId) {
    this.currentPlaceId = placeId;
    this.currentPlaceType = this.searchType;
    console.log(this.currentPlaceId, this.currentPlaceType);
  }

  async searchByDate() {
    this.dataSource = null;
    const startDate = this.startDate; //data.startDate;
    const endDate = this.endDate; //data.endDate;
    //const searchQuery = data.searchQuery;
    let _findwhere = `{"where":{"placeId":{"neq":1},"donateDate":{"between":["${new Date(startDate)}","${new Date(
      endDate
    )}"]}},"order":"id desc","include":[{"relation":"donatesDetails","scope":{"include":[{"relation":"bottleSizes"}]}},{"relation":"places"},{"relation":"myPlaces"},{"relation":"graves"},{"relation":"donateDelivery","scope":{"include":[{"relation":"delivery"},{"relation":"deliveryApproveImages"}]}}]}`;
    let bwtweenData = await this.tableService.getData(_findwhere).pipe(first()).toPromise();
    //this.dataSource = bwtweenData;
  }

  getData() {
    this.tableService.getData().subscribe((data: any) => {
      this.dataSource = data;
    });
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
