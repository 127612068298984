import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-payments-list",
  templateUrl: "./payments-list.component.html",
  styleUrls: ["./payments-list.component.scss"],
})
export class paymentsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  url: string = "payments";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Payment ID",
      id: "paymentId",
      type: "number",
      editable: false,
    },
    {
      title: "Invoice ID",
      id: "InvoiceId",
      type: "number",
      editable: false,
    },
    {
      title: "Invoice Status",
      id: "InvoiceStatus",
      type: "number",
      editable: false,
    },
    {
      title: "Invoice Value",
      id: "InvoiceValue",
      type: "number",
      editable: false,
    },
    {
      title: "Date",
      id: "CreatedDate",
      type: "datetime",
      editable: false,
    },
    {
      title: "Customer Name",
      id: "CustomerName",
      type: "text",
      editable: false,
    },
    {
      title: "Customer Mobile",
      id: "CustomerMobile",
      type: "text",
      editable: false,
    },
    {
      title: "Customer Email",
      id: "CustomerEmail",
      type: "text",
      editable: false,
    },
    {
      title: "User ID",
      id: "UserDefinedField",
      type: "text",
      editable: false,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService) {
    this.tableService._table = "payments";
    this.getData();
  }

  async ngOnInit() {
    //
  }

  getData() {
    this.tableService.getData().subscribe((data: any) => {
      this.dataSource = data;
    });
  }
}
