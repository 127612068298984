import { Component } from "@angular/core";
import { AuthService } from "./core/auth.service";
import { Observable } from "rxjs";
import { User } from "./users/shared/user.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  template: ` <app-layout></app-layout> `,
  styles: [],
})
export class AppComponent {
  title = "Sadan Admin";
  user: Observable<User>;
  saveLanguage;

  constructor(private auth: AuthService, private translate: TranslateService) {
    //this.user = this.auth.user;
    this.saveLanguage = localStorage.getItem("defaultLanguage");
    if (this.saveLanguage) {
      translate.addLangs(["en", "ar"]);
      translate.setDefaultLang(this.saveLanguage);
      translate.use(this.saveLanguage);
    } else {
      translate.addLangs(["en", "ar"]);
      translate.setDefaultLang("ar");
      translate.use("ar");
    }

    /*console.log("android " + this.platform.ANDROID);
    console.log("blink " + this.platform.BLINK);
    console.log("edge " + this.platform.EDGE);
    console.log("firefox " + this.platform.FIREFOX);
    console.log("ios " + this.platform.IOS);
    console.log("browser " + this.platform.isBrowser);
    console.log("safari " + this.platform.SAFARI);
    console.log("reident " + this.platform.TRIDENT);
    console.log("webkit " + this.platform.WEBKIT);*/
  }

  /*get isAdmin() {
    this.user.subscribe(user => {
      if(user.role === Role.Admin) {
        return true;
      }else{
        return false;
      }
    });
  }*/
}
