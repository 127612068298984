import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-data-table-form",
  templateUrl: "./data-table-form.component.html",
  styleUrls: ["./data-table-form.component.scss"],
})
export class DataTableFormComponent {
  constructor(public dialogRef: MatDialogRef<DataTableFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    /*console.log(this.data)
    this.dynamicForm = this.formBuilder.group({});
    this.data.displayData.forEach(checkData => {
      this.dynamicForm.addControl(checkData.id,this.formBuilder.control(null,[Validators.required]));
    })
    console.log(this.dynamicForm.value)*/
  }

  ngOnInit() {
    //
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getOnMutationItems(items: any[], column: any): any[] {
    return items.filter((item) => !item.notOnMutation || this.data.data[column.id] === item.value);
  }

  saveData() {
    let data = this.data.data;
    let columns = this.data.displayData;
    Object.keys(data).forEach((element) => {
      const searchInData = columns.findIndex((data) => data.id == element);
      if (searchInData == -1) {
        delete data[element];
      }
    });
    //convert data to thier real type
    columns.forEach((each, index) => {
      if (each.type == "number") {
        data[each.id] = Number(data[each.id]);
      }
    });
    this.dialogRef.close(data);
  }
}
