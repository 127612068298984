import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DoneRequestsService {
  private readonly _api = environment.sadanApi + "done-requests";
  public _token = "";

  constructor(private _http: HttpClient) {
    this._token = localStorage.token;
  }

  updateRequestsStatus(status: "approved" | "rejected", placeType: "mosques" | "medicalfacilities", placesId: number) {
    return this._http.patch(
      this._api,
      {
        placeType,
        status,
        [placeType === "mosques" ? "placesId" : "medicalfacilitiesId"]: placesId,
      },
      this._setHeaders()
    );
  }

  private _setHeaders() {
    this._token = localStorage.token;
    return {
      headers: {
        Authorization: "Bearer " + this._token,
      },
    };
  }
}
