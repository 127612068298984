import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TableService } from "src/app/core/data/table.service";
import { AuthService } from "src/app/core/auth.service";
import { MenuService } from "src/app/ui/layout/menu/menu.service";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  notification: string;
  hide = true;

  userData: any = { email: "", password: "" };
  roles = [
    {
      name: "admin",
      value: "admin",
    },
    {
      name: "barid 3liom",
      value: "barid_3liom",
    },
  ];
  //email = new FormControl('', [Validators.required , Validators.minLength(4)]);
  //password = new FormControl('', [Validators.required, Validators.minLength(6)]);

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private table: TableService,
    private authService: AuthService,
    private menuService: MenuService,
    private dataService: DataService
  ) {}

  ngOnInit() {}

  async onSubmit() {
    let _this = this;
    this.authService.LogIn(this.userData).subscribe(
      async (res: any) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("User", JSON.stringify(res));
        this.dataService.currentUser = JSON.parse(JSON.stringify(res));
        this.menuService.updateMenuData();
        _this.router.navigate(["/home"]);
      },
      (err) => {
        this.notification = err.error.error.message;
      }
    );
  }
}
