import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { first, map } from "rxjs/operators";
import { ActivatedRoute, Router, RouterEvent, NavigationStart, NavigationEnd } from "@angular/router";
import { AuthService } from "src/app/core/auth.service";

@Component({
  selector: "app-barid-list",
  templateUrl: "./barid-list.component.html",
  styleUrls: ["./barid-list.component.scss"],
})
export class baridListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "barid";
  url: string = "barid";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Delivery",
      id: "deliveryId",
      type: "text",
      editable: false,
    },
    {
      title: "Place",
      id: "placeName",
      type: "text",
      editable: false,
    },
    {
      title: "Cost",
      id: "cost",
      type: "number",
      editable: false,
    },
    {
      title: "Date",
      id: "donateDate",
      type: "datetime",
      editable: false,
    },
    {
      title: "Status",
      id: "status",
      type: "text",
      editable: false,
    },
  ];
  dataSource;
  filterData;

  constructor(
    private tableService: TableService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService
  ) {}

  async ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    this.tableService._table = "donates";
    await this.getData();
    this.getStatusFromUrl();
    /*let _findwhere = `{"where":{"donateDate":{"between":["${new Date("4-1-2020")}","${new Date()}"]}}}`;
    let bwtweenData = await this.tableService.getData(_findwhere).pipe(first()).toPromise();
    console.log(bwtweenData);*/
  }

  async getData() {
    var _findwhere =
      '{"where":{"placeId":1},"order":"id desc","include":[{"relation":"donatesDetails","scope":{"include":[{"relation":"bottleSizes"}]}},{"relation":"places"},{"relation":"myPlaces"},{"relation":"graves"},{"relation":"donateDelivery","scope":{"include":[{"relation":"delivery"},{"relation":"deliveryApproveImages"}]}}]';
    _findwhere += "}";
    let dataSource = await this.tableService
      .getData(_findwhere)
      .pipe(
        map((data: any) => {
          return data.map((newData) => {
            let deliveryId = "";
            if (newData.donateDelivery.hasOwnProperty("delivery")) {
              deliveryId = newData.donateDelivery.delivery.username;
            }
            let status = newData.donateDelivery.status;
            let placeName = "";
            if (newData.placeType == "mosques") {
              placeName = newData.places.placeName_ar + " - " + newData.places.placeName_en;
            } else if (newData.placeType == "graves") {
              placeName = newData.graves.graveName_ar + " - " + newData.graves.graveName_en;
            } else if (newData.placeType == "myPlaces") {
              placeName = newData.hasOwnProperty("myPlaces") ? newData.myPlaces.userPlaceName : "User place not found";
            }
            return { deliveryId, status, placeName, ...newData };
          });
        }),
        first()
      )
      .toPromise();
    this.dataSource = dataSource;
    this.filterData = dataSource;
  }

  async getDataForAction(data) {
    try {
      //set donate delivery data
      const donateDetails = {
        id: data.donateDeliveryId,
        status: data.status,
        deliveryId: data.deliveryId,
      };
      this.tableService._table = "donate-delivery";
      await this.tableService.SaveItem(donateDetails).pipe(first()).toPromise();
      //set delete deliveryId and status to update donate data
      delete data["status"];
      delete data["deliveryId"];
      delete data["donateDeliveryId"];
      delete data["editing"];
      this.tableService._table = "donates";
      //lets send this data
      await this.tableService.SaveItem(data).pipe(first()).toPromise();
      this.tableService.redirect(this.url);
    } catch (error) {
      alert(error.error.error.message);
    }
  }

  async getDateToFilter(data) {
    this.dataSource = null;
    this.filterData = null;
    const startDate = data.startDate;
    const endDate = data.endDate;
    const searchQuery = data.searchQuery;
    let _findwhere = `{"where":{"placeId":1,"donateDate":{"between":["${new Date(startDate)}","${new Date(
      endDate
    )}"]}},"order":"id desc","include":[{"relation":"donatesDetails","scope":{"include":[{"relation":"bottleSizes"}]}},{"relation":"places"},{"relation":"myPlaces"},{"relation":"graves"},{"relation":"donateDelivery","scope":{"include":[{"relation":"delivery"},{"relation":"deliveryApproveImages"}]}}]}`;
    let bwtweenData = await this.tableService
      .getData(_findwhere)
      .pipe(
        map((data: any) => {
          return data.map((newData) => {
            let deliveryId = "";
            if (newData.donateDelivery.hasOwnProperty("delivery")) {
              deliveryId = newData.donateDelivery.delivery.username;
            }
            let status = newData.donateDelivery.status;
            let placeName = "";
            if (newData.placeType == "mosques") {
              placeName = newData.places.placeName_ar + " - " + newData.places.placeName_en;
            } else if (newData.placeType == "graves") {
              placeName = newData.graves.graveName_ar + " - " + newData.graves.graveName_en;
            } else if (newData.placeType == "myPlaces") {
              placeName = newData.hasOwnProperty("myPlaces") ? newData.myPlaces.userPlaceName : "User place not found";
            }
            return { deliveryId, status, placeName, ...newData };
          });
        }),
        first()
      )
      .toPromise();
    this.dataSource = bwtweenData;
    this.filterData = bwtweenData;
    this.getStatusFromUrl();
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      this.getStatusFromUrl();
    }
  }

  getStatusFromUrl() {
    let baridtatus = this.route.snapshot.paramMap.get("status");
    this.filterDataWithStatus(baridtatus);
  }

  filterDataWithStatus(status: string) {
    let filterArray = [];
    this.filterData.forEach((data, index) => {
      if (data.status == status) {
        filterArray.push(data);
      }
    });
    this.dataSource = filterArray;
  }
}
