// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mailgunConfig: {
    apiKey: "aaf7eca1086e36675ecc45d08f39cf29-2ae2c6f3-6d0438c2",
    domain: "mg.kortobaa.net",
  },
  firebase: {
    apiKey: "AIzaSyC4zvuQJ_ZUxulT0-EN7eRK7kpi8PYJ3D4",
    authDomain: "sadankortobaa-bb593.firebaseapp.com",
    databaseURL: "https://sadankortobaa-bb593.firebaseio.com",
    projectId: "sadankortobaa-bb593",
    storageBucket: "sadankortobaa-bb593.appspot.com",
    messagingSenderId: "886719685210",
    appId: "1:886719685210:web:d3c158e6f8aeb4dbe92e63",
    measurementId: "G-6RR5KDYHEM",
  },
  //sadanApi:'https://api.sadankwt.com/',
  sadanApi: "https://devapi.sadankwt.com/",
  proxy: "https://proxy.sadankwt.com/",
  version: "0.1.6",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
