export const pad = (number, size) => {
  var s = String(number);
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
};

export const stringToJson = (stringData: string) => {
  var newJson = stringData.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
  newJson = newJson.replace(/'/g, '"');
  return JSON.parse(newJson);
};

export const makeFilelist = (files: File[]) => {
  const dT: any =
    new ClipboardEvent("").clipboardData || // Firefox < 62 workaround exploiting https://bugzilla.mozilla.org/show_bug.cgi?id=1422655
    new DataTransfer(); // specs compliant (as of March 2018 only Chrome)
  files.forEach((data) => {
    dT.items.add(data);
  });

  return dT.files;
};
