import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { DoneRequestsService } from "../shared/done-requests.service";

@Component({
  selector: "app-done-requests-form",
  template: "",
})
export class DoneRequestsFormComponent implements OnInit {
  constructor(private _dialog: MatDialog, private _activatedRoute: ActivatedRoute, private _router: Router) {}

  async ngOnInit() {
    this._dialog.open(DoneRequestsDialog, {
      data: {
        placeType: this._router.url.startsWith("/mosques") ? "mosques" : "medicalfacilities",
        placesId: +this._activatedRoute.snapshot.paramMap.get("id"),
      },
    });
  }
}

@Component({
  selector: "app-done-requests-dialog",
  templateUrl: "./done-requests-dialog.component.html",
  styleUrls: ["./done-requests-dialog.component.scss"],
})
export class DoneRequestsDialog {
  constructor(
    public dialogRef: MatDialogRef<DoneRequestsDialog>,
    private _router: Router,
    private _doneRequestsService: DoneRequestsService,
    @Inject(MAT_DIALOG_DATA) public data: { placesId: number; placeType: "mosques" | "medicalfacilities" }
  ) {}

  async updateRequestsStatus(status: "approved" | "rejected") {
    await this._doneRequestsService.updateRequestsStatus(status, this.data.placeType, this.data.placesId).toPromise();
    this.onNoClick();
  }

  onNoClick(): void {
    this.dialogRef.close();
    const placeType = this._router.url.startsWith("/mosques") ? "mosques" : "medicalFacilities";
    this._router.navigateByUrl(`/${placeType}-done-requests`);
  }
}
