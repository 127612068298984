import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { first, map, debounceTime, switchMap, finalize } from "rxjs/operators";
import { ActivatedRoute, Router, RouterEvent, NavigationStart, NavigationEnd } from "@angular/router";
import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { stringToJson } from "src/app/shared/helpers/customFunctions";
import { downloadCSV } from "src/app/shared/helpers/downloadCSV";
import { formatDate } from "src/app/shared/helpers/convertDate";

@Component({
  selector: "app-company-donates-list",
  templateUrl: "./company-donates-list.component.html",
  styleUrls: ["./company-donates-list.component.scss"],
})
export class companyDonatesListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("download", null) downloadLink: ElementRef;
  moduleName = "company";
  url: string = "donates";
  searchPlacesCtrl = new FormControl();
  filteredPlaces: any = [];
  isLoading = false;
  errorMsg: string;
  //current
  currentPlaceId;
  currentPlaceType;
  displayFn;
  searchType = "all";
  startDate = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate = new Date();

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Delivery",
      id: "deliveryId",
      type: "text",
      editable: false,
    },
    {
      title: "Place Name",
      id: "placeName",
      type: "text",
      editable: false,
    },
    {
      title: "Place Address",
      id: "placeAddress_ar",
      type: "text",
      editable: false,
    },
    {
      title: "Cost",
      id: "cost",
      type: "number",
      editable: false,
    },
    /*{
      title:'Details ID',
      id:'donateDeliveryId',
      type:'number',
      editable:false,
    },
    {
      title:'Details',
      id:'details',
      type:'text',
      editable:false,
    },*/
    {
      title: "Date",
      id: "donateDate",
      type: "datetime",
      editable: false,
    },
    {
      title: "Status",
      id: "status",
      type: "text",
      editable: false,
    },
  ];
  dataSource;
  filterData;

  constructor(
    private tableService: TableService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private translate: TranslateService,
    public dataService: DataService
  ) {}

  async ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    this.tableService._table = "donates";
    await this.getData();
    this.getStatusFromUrl();
    this.searchPlacesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        /*tap(() => {
          this.errorMsg = "";
          this.filteredPlaces = [];
          this.isLoading = true;
        }),*/
        switchMap((value) => {
          if (value.length > 3) {
            this.errorMsg = "";
            this.filteredPlaces = [];
            this.isLoading = true;
            return this.prepareDateAndGet(value).pipe(
              finalize(() => {
                this.isLoading = false;
              })
            );
          } else {
            return [];
          }
        })
      )
      .subscribe((data) => {
        if (data.length == 0) this.isLoading = false;
        this.filteredPlaces = data;
      });
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  prepareDateAndGet(value) {
    let filter = "";
    if (this.searchType == "mosques") {
      this.tableService._table = "places";
      filter = `{"limit":30,"where":{"or":[{"placeName_en":{"regexp":"/.*${value}.*/i"}},{"placeName_ar":{"regexp":"/.*${value}.*/i"}}, {"placeAddress_ar": {"regexp":"/.*${value}.*/i"}},{"placeAddress_en": {"regexp":"/.*${value}.*/i"}} ]},"fields":{"id":true,"placeName_en":true,"placeName_ar":true}}`;
    } else if (this.searchType == "graves") {
      this.tableService._table = "graves";
      filter = `{"limit":30,"where":{"or":[{"graveName_en":{"regexp":"/.*${value}.*/i"}},{"graveName_ar":{"regexp":"/.*${value}.*/i"}}, {"graveAddress_ar": {"regexp":"/.*${value}.*/i"}},{"graveAddress_en": {"regexp":"/.*${value}.*/i"}} ]},"fields":{"id":true,"graveName_en":true,"graveName_ar":true}}`;
    }
    return this.tableService.getData(filter);
  }

  changeCurrentPlace(placeId) {
    this.currentPlaceId = placeId;
    this.currentPlaceType = this.searchType;
  }

  changeData() {
    /*this.displayedColumns = [
      {
        title:'ID',
        id:'id',
        type:'number',
        editable:false,
      },
    ]
    this.dataSource = [{id:1}];*/
    let group = this.dataSource.reduce((r, a) => {
      /*console.log("a", a);
      console.log('r', r);*/
      r[a.placeId] = [...(r[a.placeId] || []), a];
      return r;
    }, {});
    //console.log("group", group);
  }

  async getData() {
    /*this.tableService.http.get(this.tableService._api+'users?filter={"order":"id desc","where":{"realm":"delivery"}}',this.tableService.Headers()).subscribe((data:any)=>{
      const deliveryindex = this.displayedColumns.findIndex(data => data.id == 'deliveryId');
      const placeindex = this.displayedColumns.findIndex(data => data.id == 'placeId');
      data.forEach(el => {
        this.displayedColumns[deliveryindex].list.push({value:el.id,title:el.username})
      });
      this.tableService.http.get(this.tableService._api+'places?filter={"order":"id desc","include":[{"relation":"placesTypes"}],"where":{"placeId":1}}',this.tableService.Headers()).subscribe((data:any)=>{
        data.forEach(el => {
          this.displayedColumns[placeindex].list.push({value:el.id,title:"("+el.placesTypes.typeName_en+")"+el.placeName_ar+" - "+el.placeName_en})
        });     
      });
    });*/

    var _findwhere = `{"where":{"and":[{"donateDate":{"between":["${new Date(this.startDate)}","${new Date(
      this.endDate
    )}"]}},{"companyId":"${
      this.dataService.currentUser.id
    }"}]},"order":"id desc","include":[{"relation":"donatesDetails","scope":{"include":[{"relation":"bottleSizes"}]}},{"relation":"places"},{"relation":"myPlaces","scope":{"include":[{"relation":"Zone"}]}},{"relation":"graves"},{"relation":"donateDelivery","scope":{"include":[{"relation":"delivery"},{"relation":"deliveryApproveImages"}]}}]`;
    //if(this.user.havePermission('barid_3liom'))
    //_findwhere += ',"where":{"placeId":1}';
    _findwhere += "}";
    let dataSource = await this.tableService
      .getData(_findwhere)
      .pipe(
        map((data: any) => {
          return data.map((newData) => {
            let deliveryId = "";
            if (newData.donateDelivery.hasOwnProperty("delivery")) {
              deliveryId = newData.donateDelivery.delivery.username;
            }
            let status = newData.donateDelivery.status;
            let placeName = "";
            let companyName = "";
            let placeAddress_ar = "";
            let placeAddress_en = "";
            let newDetails = "";
            //if place is mosque or barid 3lihom
            if (newData.placeType == "mosques") {
              if (newData.hasOwnProperty("places")) {
                placeName = newData.places.placeName_ar + " - " + newData.places.placeName_en;
                placeAddress_ar = newData.places.placeAddress_ar;
                placeAddress_en = newData.places.placeAddress_en;
              }
            } else if (newData.placeType == "graves") {
              //if place is grave
              if (newData.hasOwnProperty("graves")) {
                placeName = newData.graves.graveName_ar + " - " + newData.graves.graveName_en;
                placeAddress_ar = newData.graves.graveAddress_ar;
                placeAddress_en = newData.graves.graveAddress_en;
              }
            } else if (newData.placeType == "myPlaces") {
              ////if place is myplaces
              placeName = newData.hasOwnProperty("myPlaces") ? newData.myPlaces.userPlaceName : "User place not found";
              if (newData.hasOwnProperty("myPlaces")) {
                if (newData.myPlaces.hasOwnProperty("userFlat") && newData.myPlaces.userFlat.length != 0)
                  placeAddress_ar += " - شقة رقم " + newData.myPlaces.userFlat;
                if (newData.myPlaces.hasOwnProperty("userFloor") && newData.myPlaces.userFloor.length != 0)
                  placeAddress_ar += " - الدور " + newData.myPlaces.userFloor;
                if (newData.myPlaces.hasOwnProperty("userHouse") && newData.myPlaces.userHouse.length != 0)
                  placeAddress_ar += " - المنزل " + newData.myPlaces.userHouse;
                if (newData.myPlaces.hasOwnProperty("userSection") && newData.myPlaces.userSection.length != 0)
                  placeAddress_ar += " - القطعة " + newData.myPlaces.userSection;
                if (newData.myPlaces.hasOwnProperty("userAlley") && newData.myPlaces.userAlley.length != 0)
                  placeAddress_ar += " - الجادة " + newData.myPlaces.userAlley;
                if (newData.myPlaces.hasOwnProperty("Zone"))
                  placeAddress_ar += " - المنطقة " + newData.myPlaces.Zone.zone_name_ar;
                //english
                if (newData.myPlaces.hasOwnProperty("userFlat") && newData.myPlaces.userFlat.length != 0)
                  placeAddress_en += " - Flat " + newData.myPlaces.userFlat;
                if (newData.myPlaces.hasOwnProperty("userFloor") && newData.myPlaces.userFloor.length != 0)
                  placeAddress_en += " - Floor " + newData.myPlaces.userFloor;
                if (newData.myPlaces.hasOwnProperty("userHouse") && newData.myPlaces.userHouse.length != 0)
                  placeAddress_en += " - House " + newData.myPlaces.userHouse;
                if (newData.myPlaces.hasOwnProperty("userSection") && newData.myPlaces.userSection.length != 0)
                  placeAddress_en += " - Section " + newData.myPlaces.userSection;
                if (newData.myPlaces.hasOwnProperty("userAlley") && newData.myPlaces.userAlley.length != 0)
                  placeAddress_en += " - Alley " + newData.myPlaces.userAlley;
                if (newData.myPlaces.hasOwnProperty("Zone"))
                  placeAddress_en += " - Area " + newData.myPlaces.Zone.zone_name_en;
              }
            }
            companyName = newData.hasOwnProperty("company") ? newData.company.username : "No Company Info Found";
            let newLineDetails = newData.details.replace(/(?:\r\n|\r|\n)/gm, "");
            let detailsJson = stringToJson(newLineDetails);
            newDetails = detailsJson["ar"] + detailsJson["en"];
            return {
              deliveryId,
              status,
              placeName,
              newDetails,
              companyName,
              placeAddress_ar,
              placeAddress_en,
              ...newData,
            };
          });
        }),
        first()
      )
      .toPromise();
    this.dataSource = dataSource;
    this.filterData = dataSource;
    /*.subscribe((data:any) => {
      this.dataSource = data;
      this.filterData = data;
    });*/
  }

  async getDataForAction(data) {
    try {
      //set donate delivery data
      const donateDetails = {
        id: data.donateDeliveryId,
        status: data.status,
        deliveryId: data.deliveryId,
      };
      this.tableService._table = "donate-delivery";
      await this.tableService.SaveItem(donateDetails).pipe(first()).toPromise();
      //set delete deliveryId and status to update donate data
      delete data["status"];
      delete data["deliveryId"];
      delete data["donateDeliveryId"];
      delete data["editing"];
      this.tableService._table = "companyDonates";
      //lets send this data
      await this.tableService.SaveItem(data).pipe(first()).toPromise();
      this.tableService.redirect(this.url);
    } catch (error) {
      alert(error.error.error.message);
    }
    /*this.tableService.SaveItem(data).subscribe((row:any)=>{
      this.tableService.redirect(this.url);
    },(error)=>{
      alert(error.error.error.message);
    })*/
    //console.log('from main ',data)
  }

  async getDateToFilter(data) {
    this.tableService._table = "donates";
    this.dataSource = null;
    this.filterData = null;
    const startDate = data.startDate;
    const endDate = data.endDate;
    const searchQuery = data.searchQuery;
    let _findwhere = `{"where":{"and":[{"companyId":"${this.dataService.currentUser.id}"},`;
    if (this.searchType == "barid") {
      _findwhere += `{"placeType":"mosques"},`;
      _findwhere += `{"placeId":1},`;
    } else if (this.searchType == "all") {
      //if(!this.searchPlacesCtrl.value || !this.currentPlaceId) _findwhere += `{"placeId":{"neq":1}},`;
    } else {
      _findwhere += `{"placeType":"${this.searchType}"},`;
      if (this.searchPlacesCtrl.value && this.currentPlaceId) _findwhere += `{"placeId":${this.currentPlaceId}},`;
      if (!this.searchPlacesCtrl.value || !this.currentPlaceId) _findwhere += `{"placeId":{"neq":1}},`;
    }
    _findwhere += `{"donateDate":{"between":["${new Date(startDate)}","${new Date(
      endDate
    )}"]}}]},"order":"id desc","include":[{"relation":"donatesDetails","scope":{"include":[{"relation":"bottleSizes"}]}},{"relation":"places"},{"relation":"myPlaces","scope":{"include":[{"relation":"Zone"}]}},{"relation":"graves"},{"relation":"donateDelivery","scope":{"include":[{"relation":"delivery"},{"relation":"deliveryApproveImages"}]}}]}`;
    let bwtweenData = await this.tableService
      .getData(_findwhere)
      .pipe(
        map((data: any) => {
          return data.map((newData) => {
            let deliveryId = "";
            if (newData.donateDelivery.hasOwnProperty("delivery")) {
              deliveryId = newData.donateDelivery.delivery.username;
            }
            let status = newData.donateDelivery.status;
            let placeName = "";
            let companyName = "";
            let placeAddress_ar = "";
            let placeAddress_en = "";
            let newDetails = "";
            //if place is mosque or barid 3lihom
            if (newData.placeType == "mosques") {
              if (newData.hasOwnProperty("places")) {
                placeName = newData.places.placeName_ar + " - " + newData.places.placeName_en;
                placeAddress_ar = newData.places.placeAddress_ar;
                placeAddress_en = newData.places.placeAddress_en;
              }
            } else if (newData.placeType == "graves") {
              //if place is grave
              if (newData.hasOwnProperty("graves")) {
                placeName = newData.graves.graveName_ar + " - " + newData.graves.graveName_en;
                placeAddress_ar = newData.graves.graveAddress_ar;
                placeAddress_en = newData.graves.graveAddress_en;
              }
            } else if (newData.placeType == "myPlaces") {
              ////if place is myplaces
              placeName = newData.hasOwnProperty("myPlaces") ? newData.myPlaces.userPlaceName : "User place not found";
              if (newData.hasOwnProperty("myPlaces")) {
                if (newData.myPlaces.hasOwnProperty("userFlat") && newData.myPlaces.userFlat.length != 0)
                  placeAddress_ar += " - شقة رقم " + newData.myPlaces.userFlat;
                if (newData.myPlaces.hasOwnProperty("userFloor") && newData.myPlaces.userFloor.length != 0)
                  placeAddress_ar += " - الدور " + newData.myPlaces.userFloor;
                if (newData.myPlaces.hasOwnProperty("userHouse") && newData.myPlaces.userHouse.length != 0)
                  placeAddress_ar += " - المنزل " + newData.myPlaces.userHouse;
                if (newData.myPlaces.hasOwnProperty("userSection") && newData.myPlaces.userSection.length != 0)
                  placeAddress_ar += " - القطعة " + newData.myPlaces.userSection;
                if (newData.myPlaces.hasOwnProperty("userAlley") && newData.myPlaces.userAlley.length != 0)
                  placeAddress_ar += " - الجادة " + newData.myPlaces.userAlley;
                if (newData.myPlaces.hasOwnProperty("Zone"))
                  placeAddress_ar += " - المنطقة " + newData.myPlaces.Zone.zone_name_ar;
                //english
                if (newData.myPlaces.hasOwnProperty("userFlat") && newData.myPlaces.userFlat.length != 0)
                  placeAddress_en += " - Flat " + newData.myPlaces.userFlat;
                if (newData.myPlaces.hasOwnProperty("userFloor") && newData.myPlaces.userFloor.length != 0)
                  placeAddress_en += " - Floor " + newData.myPlaces.userFloor;
                if (newData.myPlaces.hasOwnProperty("userHouse") && newData.myPlaces.userHouse.length != 0)
                  placeAddress_en += " - House " + newData.myPlaces.userHouse;
                if (newData.myPlaces.hasOwnProperty("userSection") && newData.myPlaces.userSection.length != 0)
                  placeAddress_en += " - Section " + newData.myPlaces.userSection;
                if (newData.myPlaces.hasOwnProperty("userAlley") && newData.myPlaces.userAlley.length != 0)
                  placeAddress_en += " - Alley " + newData.myPlaces.userAlley;
                if (newData.myPlaces.hasOwnProperty("Zone"))
                  placeAddress_en += " - Area " + newData.myPlaces.Zone.zone_name_en;
              }
            }
            companyName = newData.hasOwnProperty("company") ? newData.company.username : "No Company Info Found";
            let newLineDetails = newData.details.replace(/(?:\r\n|\r|\n)/gm, "");
            let detailsJson = stringToJson(newLineDetails);
            newDetails = detailsJson["ar"] + detailsJson["en"];
            return {
              deliveryId,
              status,
              placeName,
              newDetails,
              companyName,
              placeAddress_ar,
              placeAddress_en,
              ...newData,
            };
          });
        }),
        first()
      )
      .toPromise();
    this.dataSource = bwtweenData;
    this.filterData = bwtweenData;
    this.getStatusFromUrl();
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      this.getStatusFromUrl();
    }
  }

  getStatusFromUrl() {
    let companyDonatestatus = this.route.snapshot.paramMap.get("status");
    this.filterDataWithStatus(companyDonatestatus);
  }

  filterDataWithStatus(status: string) {
    let filterArray = [];
    this.filterData.forEach((data, index) => {
      if (data.status == status) {
        filterArray.push(data);
      }
    });
    this.dataSource = filterArray;
  }

  //lets download data in cdv
  async downloadData() {
    let csv = "";
    const columns = await this.translateColumns(); //lets get translated header
    //const findActions = columns.findIndex(data => data == 'actions');
    //if(findActions != -1) columns.splice(findActions,1);
    let csvHeader = columns.join() + "\n";
    //lets sort data
    let csvComparedData = this.dataSource.sort(compare);
    //csv += this.dataSource.data.map(data => this.dataToCSV(data)).join("\n");//this is for all of data
    let csvData = csvComparedData.map((data) => this.dataToCSV(data)).join("\n"); //this is for filtered data
    csv = csvHeader + csvData;
    //this.renderer.setAttribute(this.downloadLink.nativeElement, "href", "data:text/plain;charset=utf-8,"+csv);
    downloadCSV("sadanData", csv);
  }

  dataToCSV(team) {
    /*const columns = this.displayedColumns;
    let finalData = [];
    columns.forEach((data:any) => {
      finalData.push(team[data.id])
    })*/
    let finalData = [
      team.id,
      team.placeName,
      team.placeAddress_ar,
      team.placeAddress_en,
      team.reciverPhone,
      team.note,
      team.cost,
      formatDate(team.donateDate),
      '"' + team.newDetails + '"',
      team.companyName,
    ];
    return finalData;
  }

  async translateColumns(): Promise<any[]> {
    let displayedColumns = [
      "ID",
      "Place",
      "Address Ar",
      "Address En",
      "Reciver Phone",
      "Note",
      "Cost",
      "Date",
      "Donate Details",
      "Company",
    ];
    //get translate data
    const trnaslate = await this.translate.translations;
    const currentLang = this.translate.currentLang;
    let translateData = [];
    displayedColumns.forEach((data) => {
      //llets loop on incoming data to get title and translate it
      translateData.push(trnaslate[currentLang]["DataTable"][data]);
    });
    return translateData;
  }
}

function compare(a, b) {
  if (a.placeAddress_ar < b.placeAddress_ar) {
    return -1;
  }
  if (a.placeAddress_ar > b.placeAddress_ar) {
    return 1;
  }
  return 0;
}
