import { Component, OnInit } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-graves-form",
  templateUrl: "./graves-form.component.html",
  styleUrls: ["./graves-form.component.scss"],
})
export class gravesFormComponent implements OnInit {
  url: string = "graves";
  new: boolean = true;
  schema: any = {
    id: 0,
    graveName_ar: "",
    graveName_en: "",
    graveGuardName: "",
    graveGuardPhone: "",
    graveCityId: 0,
    graveAddress_ar: "",
    graveAddress_en: "",
    graveStatus: 0,
    graveBoxesPerDay: 0,
    graveBoxesMax: 0,
    graveBoxesMin: 0,
    graveCurrentBoxes: 0,
    deliveryFees: 0,
  };
  graveData: any = {
    id: 0,
    graveName_ar: "",
    graveName_en: "",
    graveGuardName: "",
    graveGuardPhone: "",
    graveCityId: 0,
    graveAddress_ar: "",
    graveAddress_en: "",
    graveStatus: 0,
    graveBoxesPerDay: 0,
    graveBoxesMax: 0,
    graveBoxesMin: 0,
    graveCurrentBoxes: 0,
    deliveryFees: 0,
  };
  govs: string[] = [];

  constructor(private tableService: TableService, private route: ActivatedRoute, public dataService: DataService) {}

  async ngOnInit() {
    await this.getGovsData();
    let mosqueId = this.route.snapshot.paramMap.get("id");
    if (mosqueId) {
      this.new = false;
      await this.getMosqueData(mosqueId);
    }
    this.tableService._table = "graves";
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  async getMosqueData(mosqueId) {
    //console.log(mosqueId);
    this.tableService._table = `graves/${mosqueId}`;
    let mosqueData = await this.tableService.getData().pipe(first()).toPromise();
    this.graveData = mosqueData;
    /*.subscribe((data:any)=>{
        this.mosqueData = data[0];
      });*/
  }

  async getGovsData() {
    //console.log(mosqueId);
    this.tableService._table = "govs";
    let govs: any = await this.tableService.getData().pipe(first()).toPromise();
    this.govs = govs;
  }

  saveData() {
    if (this.validateData()) {
      this.tableService.SaveItem(this.graveData).subscribe(
        (row: any) => {
          this.dataService.notification("Saved Successfully.");
          this.tableService.redirect(this.url);
        },
        (error) => {
          alert(error.error.error.message);
        }
      );
    }
  }

  validateData(): boolean {
    //console.log(this.mosqueData);
    //Object.entries(this.schema).forEach(([schemaKey, schemaValue]: any) => {
    let returnBoolean: boolean = true;
    const schemaKeys = Object.keys(this.schema);
    const schemaVlues = Object.values(this.schema);
    const mosqueData = this.graveData;
    Object.entries(mosqueData).forEach(([key, value]: any, index) => {
      //console.log(key,schemaKeys[index])
      const schemaIndex = schemaKeys.findIndex((data) => data == key);
      if (schemaIndex != -1) {
        if (typeof value != typeof schemaVlues[schemaIndex]) {
          //alert(`${key} must be ${schemaVlues[schemaIndex]}.`);
          this.dataService.notification(`${key} must be ${schemaVlues[schemaIndex]}.`);
        } else {
          //console.log(value,schemaKeys[schemaIndex])
          if (value.length == 0) {
            //alert(`${key} is required.`);
            this.dataService.notification(`${key} is required.`);
            returnBoolean = false;
          }
        }
      } else {
        delete mosqueData[key];
      }
    });
    //})
    return returnBoolean;
  }
}
