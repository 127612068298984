import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class TableService {
  public _table: string = "";
  public _api: string = environment.sadanApi; //'http://192.168.0.105:3000/api/';//https://api.sadankwt.com/
  public _imagesurl = "https://kortobaa.s3.amazonaws.com/";
  public _options: String = JSON.stringify({ order: "id desc" });
  public _token = "";
  constructor(public http: HttpClient, private router: Router) {
    this._token = localStorage.token;
  }
  getData(_options: any = false) {
    if (_options === false) _options = this._options;
    return this.http.get(
      this._api + this._table + "?timestamp=" + new Date().getTime() + "&filter=" + _options,
      this.Headers()
    );
  }
  SaveItem(data, isPut = false) {
    if (data.id > 0 && !isPut) {
      return this.http.patch(this._api + this._table + "/" + Number(data.id), data, this.Headers());
    } else if (data.id > 0 && isPut) {
      return this.http.put(this._api + this._table + "/" + Number(data.id), data, this.Headers());
    }
    delete data.id;
    return this.http.post(this._api + this._table, data, this.Headers());
  }
  Remove(data) {
    return this.http.delete(this._api + this._table + "/" + data.id, this.Headers());
  }
  Reorder(data: { itemId: number; newPosition: number }) {
    return this.http.patch(this._api + this._table + "/reorder", data, this.Headers());
  }
  Hide(data) {
    return this.http.patch(this._api + this._table + "/" + data.id, data, this.Headers());
  }
  Headers() {
    this._token = localStorage.token;
    return {
      headers: {
        Authorization: "Bearer " + this._token,
      },
    };
  }
  redirect(_url: string) {
    this.router.navigateByUrl("pages/dashboard", { skipLocationChange: true }).then(() => this.router.navigate([_url]));
  }
}
