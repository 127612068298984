import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "src/app/shared/services/data.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-layout",
  templateUrl: "layout-component.html",
  styleUrls: ["./layout-component.scss"],
})
export class LayoutComponent {
  direction = "ltr";

  constructor(private router: Router, public dataService: DataService, private translate: TranslateService) {
    this.translate.onLangChange.subscribe((data) => {
      //console.log(data.lang)
      if (data.lang == "ar") {
        this.direction = "rtl";
      } else if (data.lang == "en") {
        this.direction = "ltr";
      }
    });
  }
}
