import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { map } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/core/auth.service";

@Component({
  selector: "app-area-list",
  templateUrl: "./area-list.component.html",
  styleUrls: ["./area-list.component.scss"],
})
export class areaListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "area";
  url: string = "area";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name ar",
      id: "zone_name_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Name en",
      id: "zone_name_en",
      type: "text",
      editable: true,
    },
    {
      title: "Governante",
      id: "gov_id",
      type: "select",
      list: [],
      editable: true,
    },
    {
      title: "Area Location Lng",
      id: "areaLng",
      type: "number",
      editable: true,
    },
    {
      title: "Area Location Lat",
      id: "areaLat",
      type: "number",
      editable: true,
    },
    {
      title: "Status",
      id: "status",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "My Places Fees",
      id: "myPlacesFees",
      type: "number",
      editable: true,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService, private dataService: DataService, public authService: AuthService) {
    this.tableService._table = "zones";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  getData() {
    this.tableService
      .getData('{"order":"id desc","include":[{"relation":"gov"}]}')
      .pipe(
        map((data: any) => {
          return data.map((areaData: any) => {
            let areaLat = "";
            let areaLng = "";
            //if zoneLocation not empty
            if (areaData.zoneLocation) {
              //get city location data and create new objects for lat & lng
              areaLat = areaData["zoneLocation"].hasOwnProperty("y") ? areaData["zoneLocation"].y : "";
              areaLng = areaData["zoneLocation"].hasOwnProperty("x") ? areaData["zoneLocation"].x : "";
            }
            return { areaLat, areaLng, ...areaData };
          });
        })
      )
      .subscribe((data: any) => {
        const placeindex = this.displayedColumns.findIndex((data) => data.id == "gov_id"); //get gov_id index
        this.tableService.http
          .get(this.tableService._api + "govs", this.tableService.Headers())
          .subscribe((data: any) => {
            data.forEach((el) => {
              this.displayedColumns[placeindex].list.push({
                value: el.id,
                title: el.governates_name_ar + " - " + el.governates_name_en,
              });
            });
          });
        this.dataSource = data;
      });
  }

  getDataForAction(data) {
    let oldLat = data["areaLat"];
    let oldLng = data["areaLng"];
    data["zoneLocation"] = { x: data["areaLng"], y: data["areaLat"] };
    delete data["areaLat"];
    delete data["areaLng"];
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.dataService.notification("Data Saved Successfully.");
        data["areaLat"] = oldLat;
        data["areaLng"] = oldLng;
        if (data["id"] == 0) this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
