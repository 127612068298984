import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { myPlacesListComponent } from "./myPlaces-list/myPlaces-list.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { TableService } from "../core/data/table.service";
import { DataTableModule } from "../data-table/data-table.module";

@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    DataTableModule,
  ],
  declarations: [myPlacesListComponent],
  providers: [TableService],
  exports: [myPlacesListComponent],
  entryComponents: [],
})
export class myPlacesModule {}
