import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { SharedModule } from "src/app/shared/shared.module";
import { DataTableModule } from "src/app/data-table/data-table.module";
import { TableService } from "src/app/core/data/table.service";
import { ImageEdit } from "src/app/shared/image-edit/image-edit.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { BaridChiratiesListComponent } from "./barid_chiraties-list/barid_chiraties-list.component";

@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatProgressSpinnerModule,
    DataTableModule,
    TranslateModule,
  ],
  declarations: [BaridChiratiesListComponent],
  providers: [TableService],
  exports: [BaridChiratiesListComponent],
  entryComponents: [ImageEdit],
})
export class BaridChiratiesModule {}
