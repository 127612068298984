import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  RouterEvent,
  NavigationStart,
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MenuComponent } from "src/app/ui/layout/menu/menu.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { env } from "process";
import { environment } from "src/environments/environment";

@Injectable()
export class DataService {
  url = "/login";
  loading = true;
  currentLanguage: string;
  currentUser = null;
  formsCols = 2;
  isMobile = false;

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    this.translate.onLangChange.subscribe((data) => {
      this.currentLanguage = data.lang;
    });

    breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.formsCols = 1;
        this.isMobile = true;
      }
    });

    /*breakpointObserver.observe([
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.maxCols = 2;
      }
    });*/

    breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
      if (result.matches) {
        this.formsCols = 2;
        this.isMobile = false;
      }
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
      this.url = event.url;
      this.currentUser = JSON.parse(localStorage.getItem("User"));
      //this.giveUserNewTime();
      //if(this.url != '/login') this.menuComponent.getMenuFromRoles();
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  notification(msg) {
    this._snackBar.open(msg, "Dismiss", {
      duration: 5000,
      panelClass: ["snackbar-color"],
    });
  }

  giveUserNewTime() {
    const expirationDuration = 1000 * 60 * 60 * 24; // 24 hours
    const prevAccepted: any = localStorage.getItem("accepted");
    const currentTime = new Date().getTime();
    const notAccepted = prevAccepted == undefined;
    const prevAcceptedExpired = prevAccepted != undefined && currentTime - prevAccepted > expirationDuration;
    if (notAccepted || prevAcceptedExpired) {
      //alert("Disclaimer: ...");
      this.notification("User Regenrated new time and get new id");
      localStorage.setItem("accepted", currentTime.toString());
    }
  }

  getVersion() {
    return environment.version;
  }
}
