import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { quickDonatesListComponent } from "./quickDonates-list/quickDonates-list.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { TableService } from "../core/data/table.service";
import { DataTableModule } from "../data-table/data-table.module";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatListModule } from "@angular/material/list";
import { RouterModule } from "@angular/router";
import { quickDonatesFormComponent } from "./quickDonates-form/quickDonates-form.component";
import { MatDividerModule } from "@angular/material/divider";
import { ImageDialog } from "../shared/image-dialog/image-dialog.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatProgressSpinnerModule, MatAutocompleteModule } from "@angular/material";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatGridListModule,
    MatToolbarModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatListModule,
    MatDividerModule,
    DataTableModule,
    TranslateModule,
    FormsModule,
    MatAutocompleteModule,
  ],
  declarations: [quickDonatesListComponent, quickDonatesFormComponent],
  providers: [TableService],
  exports: [quickDonatesListComponent, quickDonatesFormComponent],
  entryComponents: [ImageDialog],
})
export class quickDonatesModule {}
