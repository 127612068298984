import { Component, ViewChild } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { User } from "src/app/users/shared/user.model";
import { DataService } from "src/app/shared/services/data.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { SidenavService } from "../sidenav.service";
import { MatSidenav } from "@angular/material";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @ViewChild("sidenav", { static: true }) public sidenav: MatSidenav;
  showProfile = false;
  panelOpenState = true;
  currentLang: string = "en";

  constructor(
    public auth: AuthService,
    private router: Router,
    public dataService: DataService,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private sidenavService: SidenavService
  ) {
    this.currentLang = this.translate.currentLang;

    breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.panelOpenState = false;
      }
    });

    breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
      if (result.matches) {
        this.panelOpenState = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  showUserProfile() {
    //this.router.navigate(['/profile',this.auth.currentUser.id]);
  }

  useLanguage(language: string) {
    localStorage.setItem("defaultLanguage", language);
    this.translate.use(language);
    this.currentLang = language;
  }
}
