import { NgModule } from "@angular/core";
import { DataTableComponent } from "./data-table/data-table.component";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule, MatPaginatorIntl } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRippleModule, MatNativeDateModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { MyMatPaginatorIntl } from "./shared/MatPaginatorTran.service";
import { MatDialogModule } from "@angular/material/dialog";
import { DataTableFormComponent } from "./data-table-form/data-table-form.component";
import { DeleteConfirmDialog } from "../shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { FormsModule } from "@angular/forms";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    TranslateModule,
    FormsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    DragDropModule,
  ],
  exports: [DataTableComponent],
  declarations: [DataTableComponent, DataTableFormComponent],
  providers: [{ provide: MatPaginatorIntl, useClass: MyMatPaginatorIntl }, MatDatepickerModule],
  entryComponents: [DataTableFormComponent, DeleteConfirmDialog],
})
export class DataTableModule {}
