import { Component } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MenuService } from "./menu.service";
import { DataService } from "src/app/shared/services/data.service";
import { MatSidenav } from "@angular/material";
import { SidenavService } from "../sidenav.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent {
  menuData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private menuService: MenuService, public dataService: DataService, private sidenav: SidenavService) {}

  ngOnInit() {
    this.menuData = this.menuService.menuData$;
  }

  toggleRightSidenav() {
    this.sidenav.toggle();
  }
}
