import { Component, OnInit } from "@angular/core";
import { User } from "../shared/user.model";
import { AuthService } from "../../core/auth.service";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../shared/user.service";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  userId: string;

  constructor(private auth: AuthService, private route: ActivatedRoute, private userService: UserService) {
    this.userId = this.route.snapshot.paramMap.get("uid");
  }

  ngOnInit() {}
}
