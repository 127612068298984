import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from "@ngx-translate/core";

import { AuthService } from "src/app/core/auth.service";
import { TableService } from "src/app/core/data/table.service";

@Component({
  selector: "app-offers-list",
  templateUrl: "./offers-list.component.html",
  styleUrls: ["./offers-list.component.scss"],
})
export class OffersListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "offers";
  url: string = "offers";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name ar",
      id: "title_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Name en",
      id: "title_en",
      type: "text",
      editable: true,
    },
    {
      title: "From date",
      id: "startDate",
      type: "datetime",
      editable: true,
    },
    {
      title: "To date",
      id: "endDate",
      type: "datetime",
      editable: true,
    },
    {
      title: "Max Qty",
      id: "maxQty",
      type: "number",
      editable: true,
    },
    {
      title: "Sale Percentage",
      id: "salePercentage",
      type: "number",
      editable: true,
    },
    {
      title: "Product Name",
      id: "bottleSizesId",
      type: "select",
      list: [], // We will add the list value dynamically
      editable: true,
    },
    {
      title: "Status",
      id: "status",
      type: "booleanNumber",
      editable: true,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(
    private tableService: TableService,
    public authService: AuthService,
    private _translate: TranslateService
  ) {
    this.getData();
  }

  async ngOnInit() {}

  async getData() {
    this.tableService._table = "offers";

    this.tableService
      .getData('{"order":"id desc"}')
      .pipe()
      .subscribe((data: any) => {
        this.dataSource = Array.isArray(data) ? data : ([data] as any);
      });

    // Get list of bottle sizes
    this.tableService._table = "bottleSizes";
    const bottleSizesFilter = {
      order: "id desc",
      fields: ["id", "sizeTitle_ar", "sizeTitle_en", "isOffer", "companyId"],
      include: [
        {
          relation: "company",
          scope: {
            fields: ["id", "name"],
          },
        },
      ],
    };

    const bottleSizesList = (await this.tableService.getData(JSON.stringify(bottleSizesFilter)).toPromise()) as any[];

    const schemaFilter = this.displayedColumns.find((column) => column.id == "bottleSizesId");

    if (schemaFilter) {
      schemaFilter.list = [];
      bottleSizesList.forEach((bottleSize) => {
        let title = bottleSize["sizeTitle_" + this._translate.currentLang];
        if (bottleSize.company) {
          title += ` - ${bottleSize.company.name}`;
        }
        schemaFilter.list.push({
          title,
          value: bottleSize.id,
          notOnMutation: Boolean(bottleSize.isOffer),
        });
      });
    }

    this.tableService._table = "offers";
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
  }
}
