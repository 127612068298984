import { Component, OnInit } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { stringToJson } from "src/app/shared/helpers/customFunctions";
import { MatDialog } from "@angular/material/dialog";
import { ImageDialog } from "src/app/shared/image-dialog/image-dialog.component";
import { getDate, formatDate } from "src/app/shared/helpers/convertDate";
import { isImage } from "src/app/shared/helpers/checkFileType";
import { UploadImage } from "src/app/shared/services/imageUpload.service";
import { roles, rolesId } from "src/app/shared/models/interfaces";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-donates-form",
  templateUrl: "./donates-form.component.html",
  styleUrls: ["./donates-form.component.scss"],
})
export class donatesFormComponent implements OnInit {
  url: string = "donates/pending";
  zero = 0;
  schema: any = {
    id: 0,
    deviceId: "",
    userId: 0,
    donateDate: "",
    cost: 0,
    placeId: 0,
    details: "",
    placeType: "",
    graveId: 0,
    medicalfacilitiesId: 0,
    myPlaceId: 0,
    showDeliveryImages: 1,
    dbDetails: "",
    donateDelivery: {
      status: "",
      reciverName: "",
      reciverPhone: "",
      deliveryId: "",
    },
  };
  donateData: any = {
    id: 0,
    deviceId: "",
    userId: 0,
    donateDate: "",
    cost: 0,
    placeId: 0,
    details: "",
    placeType: "",
    graveId: 0,
    medicalfacilitiesId: 0,
    myPlaceId: 0,
    showDeliveryImages: 1,
    dbDetails: "",
    donateDelivery: {
      status: "",
      reciverName: "",
      reciverPhone: "",
      deliveryId: "",
    },
  };
  users: string[] = [];
  companies: string[] = [];
  placeName: string;
  imageUploading = false;

  constructor(
    private tableService: TableService,
    private route: ActivatedRoute,
    public dataService: DataService,
    private dialog: MatDialog,
    private uploadImageService: UploadImage,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    await this.getUsersData();
    await this.getCompanies();
    //await this.getPlacesData();
    let donateId = this.route.snapshot.paramMap.get("id");
    await this.getDonateData(donateId);
    this.tableService._table = "donates";
  }

  async getDonateData(donateId) {
    var _findwhere =
      '{"include":[{"relation":"medicalfacilities"},{"relation":"donatesDetails","scope":{"include":[{"relation":"bottleSizes","scope":{"include":[{"relation":"company"}]}}]}},{"relation":"barid"},{"relation":"places"},{"relation":"myPlaces"},{"relation":"graves"},{"relation":"donateDelivery","scope":{"include":[{"relation":"delivery"},{"relation":"deliveryApproveImages"}]}}]';
    _findwhere += "}";
    this.tableService._table = `donates/${donateId}`;
    let donateData: any = await this.tableService.getData(_findwhere).pipe(first()).toPromise();
    //donateData['donateDate'] = getDate(donateData['donateDate']);
    this.donateData = donateData;
    this.donateData.donateDate = formatDate(donateData.donateDate);
    let dbDetails = "";
    if (this.donateData.hasOwnProperty("donatesDetails")) {
      for (let details of this.donateData.donatesDetails) {
        const qty = details["quantity"];
        let title = "";
        let company = "";
        if (details.hasOwnProperty("bottleSizes")) {
          title = details.bottleSizes[`sizeTitle_${this.translate.currentLang}`];
          if (details.bottleSizes.hasOwnProperty("company")) {
            company = details.bottleSizes.company.name;
          }
        }
        dbDetails += `${qty} - ${title}`;
        dbDetails += company.length > 0 ? ` [${company}]` : " ";
        dbDetails += "\n";
      }
    }
    this.donateData.dbDetails = dbDetails;
    await this.getPlacesData(donateData);
  }

  async getUsersData() {
    var findWhere = `{"order":"id desc","where":{"and":[{"roleId":"${rolesId.deliveryRole}"}]}}`;
    this.tableService._table = "users";
    let users: any = await this.tableService.getData(findWhere).pipe(first()).toPromise();
    this.users = users;
  }

  async getCompanies() {
    var findWhere = `{"order":"id desc","where":{"and":[{"roleId":"${rolesId.companyRole}"}]}}`;
    this.tableService._table = "users";
    let companies: any = await this.tableService.getData(findWhere).pipe(first()).toPromise();
    this.companies = companies;
  }

  async getPlacesData(donateData) {
    if (donateData.placeType == "mosques") {
      this.placeName = `${donateData.places.placeName_ar} - ${donateData.places.placeName_en}`;
    } else if (donateData.placeType == "medicalfacilities") {
      this.placeName = `${donateData.medicalfacilities.medicalFacilityName_ar} - ${donateData.medicalfacilities.medicalFacilityName_en}`;
    } else if (donateData.placeType == "graves") {
      this.placeName = `${donateData.graves.graveName_ar} - ${donateData.graves.graveName_en}`;
    } else if (donateData.placeType == "myPlaces") {
      //this.placeName = `${donateData.myPlaces.placeName_ar} - ${donateData.myPlaces.placeName_en}`;
    } else if (donateData.placeType == "barid") {
      this.placeName = `${donateData.barid.title_ar} - ${donateData.barid.title_en}`;
    }
  }

  async saveData() {
    if (this.validateData()) {
      //lets update donates
      this.tableService._table = `donates`;
      try {
        const newDonateData = {
          id: this.donateData.id,
          companyId: this.donateData.companyId,
          showDeliveryImages: this.donateData.showDeliveryImages,
        };
        await this.tableService.SaveItem(newDonateData).pipe(first()).toPromise();
        //now lets update donte delivery
        this.tableService._table = `donate-delivery`;
        const newDeliveryData = {
          id: this.donateData.donateDelivery.id,
          deliveryId: this.donateData.donateDelivery.deliveryId,
          status: this.donateData.donateDelivery.status,
        };
        await this.tableService.SaveItem(newDeliveryData).pipe(first()).toPromise();
        this.dataService.notification("Saved Successfully.");
        this.tableService.redirect(this.url);
      } catch (error) {
        alert(error.error.error.message);
      }
    }
  }

  validateData(): boolean {
    if (this.donateData.donateDelivery.status != "") {
      return true;
    } else {
      return false;
    }
  }

  async deleteImage(imageData, index) {
    if (window.confirm("Are you sure you want to delete?")) {
      this.tableService._table = "delivery-aprrove-images";
      await this.tableService.Remove(imageData).pipe(first()).toPromise();
      this.donateData.donateDelivery.deliveryApproveImages.splice(index, 1);
      /*.subscribe((row:any)=>{
        this.tableService.redirect(this._url);
      })*/
    }
  }

  showImage(url) {
    const dialogRef = this.dialog.open(ImageDialog, {
      //width: '100%',
      data: { imgUrl: url },
    });
    //console.log(url)
  }

  setSelectedPhoto(event: FileList) {
    if (isImage(event)) {
      this.imageUploading = true;
      this.uploadImageService.uploadImage(event, "deliveryImages").subscribe((data) => {
        data.then((res) => {
          const imageURL = {
            id: 0,
            donateDeliveryId: this.donateData.donateDelivery.id,
            photoUrl: res.downloadURL,
          };
          this.tableService._table = `delivery-aprrove-images`;
          this.tableService.SaveItem(imageURL).subscribe(
            (row: any) => {
              if (row) {
                if (!this.donateData.donateDelivery.hasOwnProperty("deliveryApproveImages")) {
                  this.donateData.donateDelivery["deliveryApproveImages"] = [];
                }
                this.donateData.donateDelivery.deliveryApproveImages.push(row);
                this.dataService.notification("Image uploaded successfully.");
                this.imageUploading = false;
              }
              //this.tableService.redirect(this.url);
            },
            (error) => {
              this.imageUploading = false;
              alert(error.error.error.message);
            }
          );
        });
      });
    } else {
      alert("Please select image.");
    }
  }

  stringToJson(data) {
    return stringToJson(data);
  }
}
