import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/users/shared/user.service";
import { TableService } from "src/app/core/data/table.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  startDate = new Date(new Date().setDate(new Date().getDate() - 1));
  endDate = new Date();

  constructor(private userSrvice: UserService, private tableService: TableService) {}

  async ngOnInit() {
    /*this.tableService._table='dashboard/count';
    let count = await this.tableService.getData().pipe(first()).toPromise();
    console.log(count);*/
  }
}
