import { Component, OnInit } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { isImage } from "src/app/shared/helpers/checkFileType";
import { UploadImage } from "src/app/shared/services/imageUpload.service";

@Component({
  selector: "app-medical-facilities-form",
  templateUrl: "./medical-facilities-form.component.html",
  styleUrls: ["./medical-facilities-form.component.scss"],
})
export class MedicalFacilitiesFormComponent implements OnInit {
  url: string = "medicalFacilities";
  new: boolean = true;
  schema = {
    id: 0,
    medicalFacilityName_ar: "",
    medicalFacilityName_en: "",
    medicalFacilityBoxesMax: 0,
    medicalFacilityBoxesMin: 0,
    medicalFacilityBoxesPerDay: 0,
    medicalFacilityLocation: {
      x: 0,
      y: 0,
    },
    medicalFacilityCurrentBoxes: 0,
    medicalFacilityGovernateId: 0,
    medicalFacilityZoneId: 0,
    medicalFacilityAddress_en: "",
    medicalFacilityStatus: 0,
    medicalFacilityImage: "",
    deliveryFees: 0,
    medicalFacilityAddress_ar: "",
  };
  medicalFacilityData: any = {
    id: 0,
    medicalFacilityName_ar: "",
    medicalFacilityName_en: "",
    medicalFacilityBoxesMax: 0,
    medicalFacilityBoxesMin: 0,
    medicalFacilityBoxesPerDay: 0,
    medicalFacilityLocation: {
      x: 0,
      y: 0,
    },
    medicalFacilityCurrentBoxes: 0,
    medicalFacilityGovernateId: 0,
    medicalFacilityZoneId: 0,
    medicalFacilityAddress_en: "",
    medicalFacilityStatus: 0,
    medicalFacilityImage: "",
    deliveryFees: 0,
    medicalFacilityAddress_ar: "",
  };
  governates: string[] = [];
  zones: string[] = [];
  filteredZone: string[] = [];
  imageUploading = false;

  constructor(
    private tableService: TableService,
    private route: ActivatedRoute,
    public dataService: DataService,
    private uploadImageService: UploadImage
  ) {}

  async ngOnInit() {
    await this.getGovernatesData();
    await this.getZonesData();
    let medicalFacilityId = this.route.snapshot.paramMap.get("id");
    if (medicalFacilityId) {
      //if url has medical facility id let it old data not new
      this.new = false;
      //get data from db
      await this.getMedicalFacilityData(medicalFacilityId);
      //update gov data for medical facility
      this.getPlaceGov();
    }
    this.tableService._table = "medicalfacilities";
  }

  // Get medical facilities data
  async getMedicalFacilityData(medicalFacilityId) {
    this.tableService._table = `medicalfacilities/${medicalFacilityId}`;
    let medicalFacilityData = await this.tableService.getData().pipe(first()).toPromise();
    this.medicalFacilityData = medicalFacilityData;
  }

  //get all govs data
  async getGovernatesData() {
    this.tableService._table = "govs";
    let governates: any = await this.tableService.getData().pipe(first()).toPromise();
    this.governates = governates;
  }

  //get all areas data
  async getZonesData() {
    this.tableService._table = "zones";
    let zones: any = await this.tableService.getData().pipe(first()).toPromise();
    this.zones = zones;
  }

  //get areas by gov id to put in areas select
  getAreasByGov(govId) {
    this.filteredZone = [];
    this.zones.forEach((data: any) => {
      if (data.gov_id == govId) {
        this.filteredZone.push(data);
      }
    });
  }

  //get place gov on init
  getPlaceGov() {
    if (this.medicalFacilityData.medicalFacilityGovernateId) {
      this.getAreasByGov(this.medicalFacilityData.medicalFacilityGovernateId);
    }
  }

  saveData() {
    if (this.validateData()) {
      if (this.new) {
        this.medicalFacilityData["placeTypeId"] = 5;
      }
      this.tableService.SaveItem(this.medicalFacilityData).subscribe(
        (row: any) => {
          this.dataService.notification("Saved Successfully.");
          this.tableService.redirect(this.url);
        },
        (error) => {
          alert(error.error.error.message);
        }
      );
    }
  }

  validateData(): boolean {
    let returnBoolean: boolean = true;
    const schemaKeys = Object.keys(this.schema);
    const schemaVlues = Object.values(this.schema);
    const medicalFacilityData = this.medicalFacilityData;
    Object.entries(medicalFacilityData).forEach(([key, value]: any, index) => {
      //console.log(key,schemaKeys[index])
      const schemaIndex = schemaKeys.findIndex((data) => data == key);
      if (schemaIndex != -1) {
        if (typeof value != typeof schemaVlues[schemaIndex]) {
          //alert(`${key} must be ${schemaVlues[schemaIndex]}.`);
          this.dataService.notification(`${key} must be ${schemaVlues[schemaIndex]}.`);
        } else {
          //console.log(value,schemaKeys[schemaIndex])
          if (value.length == 0) {
            //alert(`${key} is required.`);
            this.dataService.notification(`${key} is required.`);
            returnBoolean = false;
          }
        }
      } else {
        delete medicalFacilityData[key];
      }
    });
    return returnBoolean;
  }

  setSelectedPhoto(event: FileList) {
    if (isImage(event)) {
      this.imageUploading = true;
      this.uploadImageService.uploadImage(event, "medicalFacilitiesImagesUpload").subscribe((data) => {
        data.then((res) => {
          const imageURL = {
            id: this.medicalFacilityData.id,
            medicalFacilityImage: res.downloadURL,
          };
          if (imageURL.id == 0) {
            this.medicalFacilityData.medicalFacilityImage = imageURL.medicalFacilityImage;
            this.dataService.notification("Image uploaded successfully.");
            this.imageUploading = false;
            //this.dataService.notification('Please fill data and save before upload image.');
          } else {
            this.tableService._table = `medicalfacilities`;
            this.tableService.SaveItem(imageURL).subscribe(
              (row: any) => {
                this.medicalFacilityData.medicalFacilityImage = imageURL.medicalFacilityImage;
                this.dataService.notification("Image uploaded successfully.");
                this.imageUploading = false;
              },
              (error) => {
                this.imageUploading = false;
                alert(error.error.error.message);
              }
            );
          }
        });
      });
    } else {
      alert("Please select image.");
    }
  }
}
