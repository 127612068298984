import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { first } from "rxjs/operators";
import { AuthService } from "src/app/core/auth.service";
import { roles, rolesId } from "src/app/shared/models/interfaces";

@Component({
  selector: "app-company-user-list",
  templateUrl: "./company-user-list.component.html",
  styleUrls: ["./company-user-list.component.scss"],
})
export class CompanyUserListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "company";
  url: string = "company/deliveries";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    /*{
      title:'Role',
      id:'realm',
      type:'select',
      list:[
        {value:"barid_3aliom",title:"barid 3liom"}, 
        {value:"delivery",title:"delivery"}, 
        {value:"user",title:"user"}, 
        {value:"admin",title:"admin"}
      ],
      editable:true,
    },*/
    {
      title: "Name",
      id: "username",
      type: "text",
      editable: true,
    },
    {
      title: "Email",
      id: "email",
      type: "email",
      editable: true,
    },
    {
      title: "Phone",
      id: "phone",
      type: "text",
      editable: true,
    },
    /*{
      title:'Photo',
      id:'photoUrl',
      type:'image',
      editable:false,
    },*/
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService, private dialog: MatDialog, public authService: AuthService) {
    this.tableService._table = "companyDeliveries";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  getData() {
    this.tableService.getData().subscribe((data: any) => {
      const newData = data.map((eachData) => {
        let userData = eachData.delivery;
        return {
          id: userData.id,
          username: userData.username,
          email: userData.email,
          phone: userData.phone,
          photoUrl: userData.photoUrl,
          emailVerified: userData.emailVerified,
        };
        //lets return user info
      });
      this.dataSource = newData;
    });
  }

  async getDataForAction(data) {
    //console.log(data)
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: "250px",
      data: { password: "" },
      disableClose: true,
    });

    let newPassword = await dialogRef.afterClosed().pipe(first()).toPromise();

    if (newPassword) {
      data["password"] = newPassword;
    }

    delete data["editing"];
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}

//password dialog
@Component({
  selector: "dialog-overview-example-dialog",
  template: `
    <div mat-dialog-content>
      <p>{{ "PasswordDialog.Write password" | translate }}</p>
      <mat-form-field>
        <input matInput [(ngModel)]="data.password" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">{{ "PasswordDialog.No Thanks" | translate }}</button>
      <button mat-button [mat-dialog-close]="data.password" cdkFocusInitial>
        {{ "PasswordDialog.Ok" | translate }}
      </button>
    </div>
  `,
})
export class DialogOverviewExampleDialog {
  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
