import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "src/app/core/auth.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-myPlaces-list",
  templateUrl: "./myPlaces-list.component.html",
  styleUrls: ["./myPlaces-list.component.scss"],
})
export class myPlacesListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "myPlaces";
  url: string = "myPlaces";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name",
      id: "userPlaceName",
      type: "text",
      editable: false,
    },
    {
      title: "Area",
      id: "userArea",
      type: "text",
      editable: false,
    },
    {
      title: "Street",
      id: "userStreet",
      type: "text",
      editable: false,
    },
    {
      title: "House",
      id: "userHouse",
      type: "text",
      editable: false,
    },
    {
      title: "Floor",
      id: "userFloor",
      type: "text",
      editable: false,
    },
    {
      title: "Flat",
      id: "userFlat",
      type: "text",
      editable: false,
    },
    {
      title: "Phone",
      id: "userPhone",
      type: "text",
      editable: false,
    },
    {
      title: "Date",
      id: "created_at",
      type: "datetime",
      editable: false,
    },
    {
      title: "Status",
      id: "status",
      type: "booleanNumber",
      editable: false,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService, public authService: AuthService) {
    this.tableService._table = "my-places/all";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  async getDateToFilter(data) {
    this.dataSource = null;
    const startDate = data.startDate;
    const endDate = data.endDate;
    const searchQuery = data.searchQuery;
    let _findwhere = `{"where":{"created_at":{"between":["${new Date(startDate)}","${new Date(
      endDate
    )}"]}},"order":"id desc"}`;
    let bwtweenData: any = await this.tableService.getData(_findwhere).pipe(first()).toPromise();
    this.dataSource = bwtweenData;
  }

  getData() {
    this.tableService.getData('{"order":"id desc"}').subscribe((data: any) => {
      this.dataSource = data;
    });
  }
}
