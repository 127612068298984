import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { TranslateService } from "@ngx-translate/core";
import { ImageEdit } from "src/app/shared/image-edit/image-edit.component";
import { base64MimeType, dataURLtoFile } from "src/app/shared/helpers/checkFileType";
import { first } from "rxjs/operators";
import { makeFilelist } from "src/app/shared/helpers/customFunctions";
import { UploadImage } from "src/app/shared/services/imageUpload.service";
import { MatDialog } from "@angular/material";
import { isYoutubeLink, validateYoutubeLink } from "src/app/shared/helpers/formControlrs";
import { NgModel } from "@angular/forms";

@Component({
  selector: "app-sadnInfo-list",
  templateUrl: "./sadnInfo-list.component.html",
  styleUrls: ["./sadnInfo-list.component.scss"],
})
export class sadnInfoListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "sadanInfo";
  url: string = "sadanInfo";
  pageLoading = false;

  baridData = {
    logo: "",
    youtubeLink: "",
  };

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Privacy ar",
      id: "privacy_ar",
      type: "textarea",
      editable: true,
    },
    {
      title: "Privacy en",
      id: "privacy_en",
      type: "textarea",
      editable: true,
    },
    {
      title: "Barid ar",
      id: "baridText_ar",
      type: "textarea",
      editable: true,
    },
    {
      title: "Barid en",
      id: "baridText_en",
      type: "textarea",
      editable: true,
    },
    {
      title: "Mosques Service",
      id: "mosques",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "Graves Service",
      id: "graves",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "Barid-3lihom Service",
      id: "barid",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "My Places Service",
      id: "places",
      type: "booleanNumber",
      editable: true,
    },
  ];
  //dataSource: MatTableDataSource<any>;
  dataSource = {
    id: 0,
    privacy_ar: "",
    privacy_en: "",
    baridText_ar: "",
    baridText_en: "",
    mosques: 0,
    graves: 0,
    barid: 0,
    places: 0,
    baridFees: 0,
    quickDonate_ar: "",
    quickDonate_en: "",
    placesDeliveryTime_ar: "",
    placesDeliveryTime_en: "",
    marketDeliveryTime_ar: "",
    marketDeliveryTime_en: "",
    multiDonationDeliveryTime_ar: "",
    multiDonationDeliveryTime_en: "",
    baridLogo: "",
    medicalFacilities: 0,
    medicalFacilitiesText_ar: "",
    medicalFacilitiesText_en: "",
  };

  constructor(
    private tableService: TableService,
    public authService: AuthService,
    public dataService: DataService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private uploadImageService: UploadImage
  ) {
    this.tableService._table = "sadanInfo";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  getData() {
    this.tableService.getData().subscribe((data: any) => {
      this.dataSource = data;
      if (isYoutubeLink(this.dataSource.baridLogo)) {
        this.baridData.youtubeLink = this.dataSource.baridLogo;
      } else {
        this.baridData.logo = this.dataSource.baridLogo;
      }
    });
  }

  handleYoutubeLinkChange({ control }: NgModel) {
    this.baridData.logo = "";
    validateYoutubeLink(control);
  }

  async setSelectedPhoto() {
    //get image and give it to ImageEdit Dialog
    const dialogRef = this.dialog.open(ImageEdit, {
      panelClass: "my-full-screen-dialog",
      data: { imageUrl: event },
      disableClose: true,
    });

    //lets wait dialog to close or edit image
    let newImage = await dialogRef.afterClosed().pipe(first()).toPromise();

    //if dialog not canceled
    if (newImage) {
      //now lets check if imageData is base64 or not
      if (base64MimeType(newImage)) {
        //convert base64 to File to send it to firebase as file
        const imageData: File = dataURLtoFile(newImage, "img.png");
        //lets create file list withh array of our images
        const createFileList: FileList = makeFilelist([imageData]);
        try {
          this.pageLoading = true;
          //now we are waiting to upload photo to firebase and get URL
          let newImageUrl = await this.uploadImageService
            .uploadImage(createFileList, "bottlesCompanies")
            .pipe(first())
            .toPromise();
          //set new URL to save in data base
          this.baridData["logo"] = newImageUrl.downloadURL;
        } catch (error) {
          this.pageLoading = false;
          //catch error and display it
          alert(error.error.error.message);
        }
      } else {
        //if image is old url and not base64 set photoUrl to old URL
        this.baridData["logo"] = newImage;
      }
      this.baridData.youtubeLink = "";
      this.pageLoading = false;
    } else {
      alert("Please insert image to complete your process.");
    }
  }

  getDataForAction() {
    let data = this.dataSource;
    data.medicalFacilities = Number(data.medicalFacilities);
    data["baridFees"] = Number(data["baridFees"]);
    data.baridLogo = this.baridData.logo || this.baridData.youtubeLink;
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.dataService.notification(this.translate.instant("notifications.Data saved successfully"));
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
  }
}
