import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { ImageEdit } from "src/app/shared/image-edit/image-edit.component";
import { first } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { UploadImage } from "src/app/shared/services/imageUpload.service";
import { dataURLtoFile, base64MimeType } from "src/app/shared/helpers/checkFileType";
import { makeFilelist } from "src/app/shared/helpers/customFunctions";
import { AuthService } from "src/app/core/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-bottle_sizes-list",
  templateUrl: "./bottle_sizes-list.component.html",
  styleUrls: ["./bottle_sizes-list.component.scss"],
})
export class bottle_sizesListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "bottle_sizes";
  url: string = "bottle_sizes";

  imageUploading: boolean = false;

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name ar",
      id: "sizeTitle_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Name en",
      id: "sizeTitle_en",
      type: "text",
      editable: true,
    },
    {
      title: "Description ar",
      id: "sizeDescription_ar",
      type: "textarea",
      editable: true,
    },
    {
      title: "Description en",
      id: "sizeDescription_en",
      type: "textarea",
      editable: true,
    },
    {
      title: "Price",
      id: "sizePrice",
      type: "number",
      editable: true,
    },
    {
      title: "Company",
      id: "companyId",
      type: "select",
      list: [],
      editable: true,
    },
    {
      title: "section",
      id: "sectionId",
      type: "select",
      list: [],
      editable: true,
    },
    {
      title: "Status",
      id: "sizeStatus",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "is pallet",
      id: "isPallet",
      type: "booleanNumber",
      editable: true,
    },
    /*{
      title:'is offer',
      id:'isOffer',
      type:'booleanNumber',
      editable:true,
    },*/
    {
      title: "Boxes Quantity",
      id: "sizeQty",
      type: "number",
      editable: true,
    },
    {
      title: "is mosques",
      id: "isMosques",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "is medical facilities",
      id: "isMedicalFacilities",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "is graves",
      id: "isGraves",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "is barid",
      id: "isBarid",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "is my places",
      id: "isMyPlaces",
      type: "booleanNumber",
      editable: true,
    },
    {
      title: "Photo",
      id: "photoUrl",
      type: "image",
      editable: false,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(
    private tableService: TableService,
    private dialog: MatDialog,
    private dataService: DataService,
    private uploadImageService: UploadImage,
    public authService: AuthService,
    private transalate: TranslateService
  ) {
    this.tableService._table = "bottleSizes";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  async getData() {
    const data: any = await this.tableService.getData('{"order":"position asc"}').pipe(first()).toPromise();
    //get copanies
    this.tableService._table = "bottles-companies";
    const companies: any = await this.tableService.getData('{"order":"id desc"}').pipe(first()).toPromise();
    const dataIndex = this.displayedColumns.findIndex((compData) => compData.id == "companyId");
    this.displayedColumns[dataIndex].list.push({ value: 0, title: "No Company" });
    companies.forEach((company) => {
      this.displayedColumns[dataIndex].list.push({ value: company.id, title: company.name });
    });
    //get sections
    this.tableService._table = "bottles-sections";
    const sections: any = await this.tableService.getData('{"order":"id desc"}').pipe(first()).toPromise();
    const dataIndex2 = this.displayedColumns.findIndex((compData) => compData.id == "sectionId");
    this.displayedColumns[dataIndex2].list.push({ value: 0, title: "No Section" });
    sections.forEach((company) => {
      this.displayedColumns[dataIndex2].list.push({
        value: company.id,
        title: company["title_" + this.transalate.currentLang],
      });
    });
    this.tableService._table = "bottleSizes";
    this.dataSource = data;
  }

  async getDataForAction(data) {
    //get image and give it to ImageEdit Dialog
    const dialogRef = this.dialog.open(ImageEdit, {
      panelClass: "my-full-screen-dialog",
      data: { imageUrl: data.photoUrl },
      disableClose: true,
    });

    //lets wait dialog to close or edit image
    let newImage = await dialogRef.afterClosed().pipe(first()).toPromise();

    //if dialog not canceled
    if (newImage) {
      //now lets check if imageData is base64 or not
      if (base64MimeType(newImage)) {
        //convert base64 to File to send it to firebase as file
        const imageData: File = dataURLtoFile(newImage, "img.png");
        //lets create file list withh array of our images
        const createFileList: FileList = makeFilelist([imageData]);
        try {
          this.imageUploading = true;
          //now we are waiting to upload photo to firebase and get URL
          let newImageUrl = await this.uploadImageService
            .uploadImage(createFileList, "bottleSizes")
            .pipe(first())
            .toPromise();
          //set new URL to save in data base
          data["photoUrl"] = newImageUrl.downloadURL;
        } catch (error) {
          this.imageUploading = false;
          //catch error and display it
          alert(error.error.error.message);
        }
      } else {
        //if image is old url and not base64 set photoUrl to old URL
        data["photoUrl"] = newImage;
      }

      //delete editing object
      delete data["editing"];
      //check if its not a pallet
      if (data["isPallet"] == 0) {
        //set size quamtity to 1 box
        data["sizeQty"] = 1;
      }
      //lets save incoming data
      this.tableService.SaveItem(data).subscribe(
        (row: any) => {
          this.imageUploading = false;
          this.tableService.redirect(this.url);
        },
        (error) => {
          alert(error.error.error.message);
        }
      );
    } else {
      alert("Please insert image to complete your process.");
    }
  }
}
