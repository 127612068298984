import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { ImageEdit } from "src/app/shared/image-edit/image-edit.component";
import { first } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { UploadImage } from "src/app/shared/services/imageUpload.service";
import { dataURLtoFile, base64MimeType } from "src/app/shared/helpers/checkFileType";
import { makeFilelist } from "src/app/shared/helpers/customFunctions";
import { AuthService } from "src/app/core/auth.service";
import { AdsFormComponent } from "../ads-form/ads-form.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-ads-list",
  templateUrl: "./ads-list.component.html",
  styleUrls: ["./ads-list.component.scss"],
})
export class AdsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "ads";
  url: string = "ads";

  imageUploading: boolean = false;

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Title ar",
      id: "title_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Title en",
      id: "title_en",
      type: "text",
      editable: true,
    },
    {
      title: "section",
      id: "section",
      type: "select",
      list: [
        { value: "global", title: "Global" },
        { value: "mosques", title: "Mosques" },
        { value: "graves", title: "Graves" },
        { value: "myPlaces", title: "My Places" },
        { value: "quickDonate", title: "Quick Donate" },
      ],
      editable: true,
    },
    {
      title: "Fixed",
      id: "isFixed",
      type: "boolean",
      editable: true,
    },
    {
      title: "placeId",
      id: "placeId",
      type: "number",
      //list:[{value:1,title:"Mosques"}],
      editable: true,
    },
    {
      title: "productCompanyId",
      id: "productCompanyId",
      type: "number",
      //list:[{value:1,title:"Mosques"}],
      editable: true,
    },
    {
      title: "productId",
      id: "productId",
      type: "number",
      //list:[{value:1,title:"Mosques"}],
      editable: true,
    },
    {
      title: "expiryDate",
      id: "expiryDate",
      type: "datetime",
      editable: true,
    },
    {
      title: "Photo",
      id: "photoUrl",
      type: "image",
      editable: false,
    },
    {
      title: "Status",
      id: "status",
      type: "booleanNumber",
      editable: true,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(
    private tableService: TableService,
    private dialog: MatDialog,
    private dataService: DataService,
    private uploadImageService: UploadImage,
    private translate: TranslateService,
    public authService: AuthService
  ) {
    this.tableService._table = "ads";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  async getData() {
    const data: any = await this.tableService.getData('{"order":"id desc"}').pipe(first()).toPromise();
    /*this.tableService._table='bottles-companies';
    const companies:any = await this.tableService.getData('{"order":"id desc"}').pipe(first()).toPromise();
    const dataIndex = this.displayedColumns.findIndex(compData => compData.id == "companyId");
    this.displayedColumns[dataIndex].list.push({value:0,title:"No Company"});
    companies.forEach(company => {
        this.displayedColumns[dataIndex].list.push({value:company.id,title:company.name});
    });*/
    this.tableService._table = "ads";
    this.dataSource = data;
  }

  async takeAction(event) {
    const dialogRef = await this.dialog
      .open(AdsFormComponent, {
        panelClass: "my-full-screen-dialog",
        data: event.data,
        direction: this.translate.currentLang == "ar" ? "rtl" : "ltr",
        //disableClose: true,
      })
      .afterClosed()
      .toPromise();
    if (!dialogRef) return;
    if (event.action == "create") {
      //
    } else if (event.action == "update") {
      //
    }

    dialogRef.isGenric = dialogRef.section === "global";

    await this.getDataForAction(dialogRef);
  }

  async getDataForAction(data) {
    //get image and give it to ImageEdit Dialog
    const dialogRef = this.dialog.open(ImageEdit, {
      panelClass: "my-full-screen-dialog",
      data: { imageUrl: data.photoUrl, imageRatio: data.isFixed ? 2 : 9 / 16 },
      disableClose: true,
    });

    //lets wait dialog to close or edit image
    let newImage = await dialogRef.afterClosed().pipe(first()).toPromise();

    //if dialog not canceled
    if (newImage) {
      //now lets check if imageData is base64 or not
      if (base64MimeType(newImage)) {
        //convert base64 to File to send it to firebase as file
        const imageData: File = dataURLtoFile(newImage, "img.png");
        //lets create file list withh array of our images
        const createFileList: FileList = makeFilelist([imageData]);
        try {
          this.imageUploading = true;
          //now we are waiting to upload photo to firebase and get URL
          let newImageUrl = await this.uploadImageService.uploadImage(createFileList, "ads").pipe(first()).toPromise();
          //set new URL to save in data base
          data["photoUrl"] = newImageUrl.downloadURL;
        } catch (error) {
          this.imageUploading = false;
          //catch error and display it
          alert(error.error.error.message);
        }
      } else {
        //if image is old url and not base64 set photoUrl to old URL
        data["photoUrl"] = newImage;
      }

      //delete editing object
      delete data["editing"];
      //check if its not a pallet
      if (data["isPallet"] == 0) {
        //set size quamtity to 1 box
        data["sizeQty"] = 1;
      }
      //lets save incoming data
      this.tableService.SaveItem(data).subscribe(
        (row: any) => {
          this.imageUploading = false;
          this.tableService.redirect(this.url);
        },
        (error) => {
          alert(error.error.error.message);
        }
      );
    } else {
      alert("Please insert image to complete your process.");
    }
  }
}
