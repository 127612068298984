import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ThemeDirective } from "./directive/theme.directive";
import { PlatformModule } from "@angular/cdk/platform";
import { MatDialogModule } from "@angular/material/dialog";
import { ImageDialog } from "./image-dialog/image-dialog.component";
import { ImageEdit } from "./image-edit/image-edit.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { TranslateModule } from "@ngx-translate/core";
import { DeleteConfirmDialog } from "./delete-confirm-dialog/delete-confirm-dialog.component";
import { MatButtonModule } from "@angular/material";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    ImageCropperModule,
    TranslateModule,
  ],
  declarations: [ThemeDirective, ImageDialog, ImageEdit, DeleteConfirmDialog],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ThemeDirective,
    PlatformModule,
    ImageDialog,
    ImageEdit,
    DeleteConfirmDialog,
  ],
  providers: [],
  entryComponents: [],
})
export class SharedModule {}
