import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";

@Component({
  selector: "app-done-requests-list",
  templateUrl: "./done-requests-list.component.html",
  styleUrls: ["./done-requests-list.component.scss"],
})
export class DoneRequestsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  url: string;
  title: string;
  urlAction: string = "/mosques-done-requests/form";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private _router: Router, private tableService: TableService) {}

  async ngOnInit() {
    let tableUrl: string;
    if (this._router.url.startsWith("/mosques")) {
      tableUrl = "places/done/requests";
      this.url = "mosques-done-requests";
      this.urlAction = "/mosques-done-requests/form";
      this.title = "Mosques Done Requests";

      this.displayedColumns.push(
        {
          title: "Name ar",
          id: "placeName_ar",
          type: "text",
          editable: false,
        },
        {
          title: "Name en",
          id: "placeName_en",
          type: "text",
          editable: false,
        },
        {
          title: "Address Ar",
          id: "placeAddress_ar",
          type: "text",
          editable: false,
        },
        {
          title: "Address En",
          id: "placeAddress_en",
          type: "text",
          editable: false,
        }
      );
    } else {
      tableUrl = "medicalfacilities/done/requests";
      this.url = "medicalFacilities-done-requests";
      this.urlAction = "/medicalFacilities-done-requests/form";
      this.title = "Medical Facilities Done Requests";

      this.displayedColumns.push(
        {
          title: "Name ar",
          id: "medicalFacilityName_ar",
          type: "text",
          editable: false,
        },
        {
          title: "Name en",
          id: "medicalFacilityName_en",
          type: "text",
          editable: false,
        },
        {
          title: "Address Ar",
          id: "medicalFacilityAddress_ar",
          type: "text",
          editable: false,
        },
        {
          title: "Address En",
          id: "medicalFacilityAddress_en",
          type: "text",
          editable: false,
        }
      );
    }

    this.displayedColumns.push({
      title: "Done Requests Num",
      id: "numOfDoneRequests",
      type: "number",
      editable: false,
    });

    this.tableService._table = tableUrl;

    this.getData();
  }

  getData() {
    this.tableService.getData('{"order":"id desc","where":{"and":[{"mainRole":"0"}]}}').subscribe((data: any) => {
      this.dataSource = data;
    });
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
  }
}
