import { Injectable } from "@angular/core";
import { AngularFireUploadTask, AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs";
import { UploadTaskSnapshot } from "@angular/fire/storage/interfaces";
import { finalize, tap, map, last, first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UploadImage {
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string | UploadTaskSnapshot>;
  //path = 'hacakthonCover'

  constructor(private storage: AngularFireStorage) {}

  uploadImage(event: FileList, storagePath: string) {
    const file = event.item(0);
    const path = `${storagePath}/${new Date().getDate()}${new Date().getMonth()}${new Date().getFullYear()}/${new Date().getTime()}_${
      file.name
    }`;
    const fileRef = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();
    return (this.snapshot = this.task.snapshotChanges().pipe(
      last(),
      map(async (actions) => {
        const downloadURL = await fileRef.getDownloadURL().pipe(first()).toPromise();
        return { downloadURL, ...actions };
      })
      /*tap(snap => {
            if (snap.bytesTransferred === snap.totalBytes) {
            }
            }),
            finalize(() => {
            //return fileRef.getDownloadURL();
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(downloadURL => {
                return downloadURL
            });
            })*/
    ));
  }
}
