import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { messagesListComponent } from "./messages-list/messages-list.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { TableService } from "../core/data/table.service";
import { DataTableModule } from "../data-table/data-table.module";
import { messagesBodyComponent } from "./messages-body/messages-body.component";
import { MatRippleModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { TranslateModule } from "@ngx-translate/core";
import { DeleteConfirmDialog } from "../shared/delete-confirm-dialog/delete-confirm-dialog.component";

@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    DataTableModule,
    MatRippleModule,
    MatDividerModule,
    TranslateModule,
  ],
  declarations: [messagesListComponent, messagesBodyComponent],
  providers: [TableService],
  exports: [messagesListComponent],
  entryComponents: [DeleteConfirmDialog],
})
export class messagesModule {}
