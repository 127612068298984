import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "src/app/core/auth.service";

@Component({
  selector: "app-mosques-list",
  templateUrl: "./mosques-list.component.html",
  styleUrls: ["./mosques-list.component.scss"],
})
export class mosquesListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "mosques";
  url: string = "mosques";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name ar",
      id: "placeName_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Name en",
      id: "placeName_en",
      type: "text",
      editable: true,
    },
    {
      title: "Address Ar",
      id: "placeAddress_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Address En",
      id: "placeAddress_en",
      type: "text",
      editable: true,
    },
    {
      title: "Details Status",
      id: "detailsStatus",
      type: "number",
      editable: true,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService, public authService: AuthService) {
    this.tableService._table = "places";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  getData() {
    this.tableService.getData('{"order":"id desc","where":{"and":[{"placeTypeId":1}]}}').subscribe((data: any) => {
      this.dataSource = data;
    });
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
