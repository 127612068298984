import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "./core/core.module";
import { AuthModule } from "./auth/auth.module";
import { UiModule } from "./ui/ui.module";
import { SharedModule } from "./shared/shared.module";
import { UsersModule } from "./users/users.module";
import { HomeModule } from "./home/home.module";
import { RouterModule } from "@angular/router";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DataTableModule } from "./data-table/data-table.module";
import { donatesModule } from "./donates/donates.module";
import { mosquesModule } from "./mosques/mosques.module";
import { gravesModule } from "./graves/graves.module";
import { placeTypesModule } from "./settings/placeTypes/placeTypes.module";
import { bottle_sizesModule } from "./settings/bottle_sizes/bottle_sizes.module";
import { rolesModule } from "./settings/roles/roles.module";
import { devicesModule } from "./reports/devices/devices.module";
import { messagesModule } from "./messages/messages.module";
import { faqModule } from "./faq/faq.module";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { govModule } from "./settings/gov/gov.module";
import { areaModule } from "./settings/area/area.module";
import { companyUsersModule } from "./company/company-users/company-users.module";
import { companyDonatesModule } from "./company/company-donates/company-donates.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { sadnInfoModule } from "./settings/sadnInfo/sadnInfo.module";
import { paymentsModule } from "./reports/payments/payments.module";
import { myPlacesModule } from "./myPlaces/myPlaces.module";
import { baridModule } from "./barid/barid.module";
import { placesReportsModule } from "./reports/places-reports/places-reports.module";
import { companyModule } from "./settings/company/company.module";
import { quickDonatesModule } from "./quickDonates/quickDonates.module";
import { AdsModule } from "./settings/ads/ads.module";
import { BottleSectionsModule } from "./settings/bottle_sections/bottle_sections.module";
import { BaridChiratiesModule } from "./settings/barid_chiraties/barid_chiraties.module";
import { OffersModule } from "./settings/offers/offers.module";
import { FriendsAppsModule } from "./settings/friendsApps/friendsApps.module";
import { DoneRequestsModule } from "./settings/doneRequests/doneRequests.module";
import { MedicalFacilitiesModule } from "./medicalFacilities/medicalFacilities.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
  //return new TranslateHttpLoader(http,'/admin/assets/i18n/');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    CoreModule,
    AuthModule,
    UiModule,
    SharedModule,
    HomeModule,
    UsersModule,
    donatesModule,
    mosquesModule,
    MedicalFacilitiesModule,
    gravesModule,
    placeTypesModule,
    bottle_sizesModule,
    FriendsAppsModule,
    govModule,
    OffersModule,
    rolesModule,
    DoneRequestsModule,
    devicesModule,
    messagesModule,
    companyUsersModule,
    companyDonatesModule,
    faqModule,
    areaModule,
    sadnInfoModule,
    paymentsModule,
    myPlacesModule,
    baridModule,
    placesReportsModule,
    DataTableModule,
    companyModule,
    quickDonatesModule,
    AdsModule,
    BottleSectionsModule,
    BaridChiratiesModule,
    //material
    MatSnackBarModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
  ],
  exports: [TranslateModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
