export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const getDate = (date) => {
  const newData = new Date(date);
  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  return newData.toLocaleDateString();
  //d.toLocaleDateString('ar-EG', options);
};

export const formatDate = (date) => {
  const newData = new Date(date);
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var day = newData.getDate();
  var monthIndex = newData.getMonth();
  var year = newData.getFullYear();
  var hours = newData.getHours();
  var minutes = newData.getMinutes();

  return day + " " + monthNames[monthIndex] + " " + year + " - " + hours + ":" + minutes;
};
