import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "src/app/shared/shared.module";
import { DataTableModule } from "src/app/data-table/data-table.module";
import { DialogOverviewExampleDialog, CompanyUserListComponent } from "./company-user-list/company-user-list.component";
import { companyUserservice } from "./shared/company-user.service";
import { TableService } from "src/app/core/data/table.service";

@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    DataTableModule,
    TranslateModule,
  ],
  declarations: [CompanyUserListComponent, DialogOverviewExampleDialog],
  providers: [companyUserservice, TableService],
  exports: [CompanyUserListComponent],
  entryComponents: [DialogOverviewExampleDialog],
})
export class companyUsersModule {}
