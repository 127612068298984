import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-placeTypes-list",
  templateUrl: "./placeTypes-list.component.html",
  styleUrls: ["./placeTypes-list.component.scss"],
})
export class placeTypesListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  url: string = "place_types";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name ar",
      id: "typeName_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Name en",
      id: "typeName_en",
      type: "text",
      editable: true,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService) {
    this.tableService._table = "placesTypes";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  getData() {
    this.tableService.getData().subscribe((data: any) => {
      this.dataSource = data;
    });
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
