import { NgModule } from "@angular/core";
import { DoneRequestsListComponent } from "./done-requests-list/done-requests-list.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { SharedModule } from "src/app/shared/shared.module";
import { DataTableModule } from "src/app/data-table/data-table.module";
import { TableService } from "src/app/core/data/table.service";
import { DoneRequestsDialog, DoneRequestsFormComponent } from "./done-requests-form/done-requests-form.component";
import { TranslateModule } from "@ngx-translate/core";
import {
  MatGridListModule,
  MatToolbarModule,
  MatSelectModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatListModule,
} from "@angular/material";
import { RouterModule } from "@angular/router";
import { MatTreeModule } from "@angular/material/tree";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatGridListModule,
    MatToolbarModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatCheckboxModule,
    DataTableModule,
    TranslateModule,
  ],
  declarations: [DoneRequestsListComponent, DoneRequestsFormComponent, DoneRequestsDialog],
  providers: [TableService],
  exports: [DoneRequestsListComponent, DoneRequestsFormComponent],
  entryComponents: [DoneRequestsDialog],
})
export class DoneRequestsModule {}
