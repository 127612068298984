import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { ImageEdit } from "src/app/shared/image-edit/image-edit.component";
import { first } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { UploadImage } from "src/app/shared/services/imageUpload.service";
import { dataURLtoFile, base64MimeType } from "src/app/shared/helpers/checkFileType";
import { makeFilelist } from "src/app/shared/helpers/customFunctions";
import { AuthService } from "src/app/core/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-friends-apps-list",
  templateUrl: "./friends-apps-list.component.html",
  styleUrls: ["./friends-apps-list.component.scss"],
})
export class FriendsAppsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "friendsApps";
  url: string = "friendsApps";

  imageUploading: boolean = false;

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "App Name",
      id: "appName",
      type: "text",
      editable: true,
    },
    {
      title: "Web Link",
      id: "webLink",
      type: "text",
      editable: true,
    },
    {
      title: "Ios Link",
      id: "iosLink",
      type: "text",
      editable: true,
    },
    {
      title: "Android Link",
      id: "androidLink",
      type: "text",
      editable: true,
    },
    {
      title: "Photo",
      id: "appLogo",
      type: "image",
      editable: false,
    },
  ];
  dataSource: any;

  constructor(
    private tableService: TableService,
    private dialog: MatDialog,
    private dataService: DataService,
    private uploadImageService: UploadImage,
    private translate: TranslateService,
    public authService: AuthService
  ) {
    this.tableService._table = "friends-apps";
    this.getData();
  }

  async ngOnInit() {}

  async getData() {
    const data: any = await this.tableService.getData('{"order":"id desc"}').pipe(first()).toPromise();

    this.tableService._table = "friends-apps";
    this.dataSource = data;
  }

  async getDataForAction(data) {
    //get image and give it to ImageEdit Dialog
    const dialogRef = this.dialog.open(ImageEdit, {
      panelClass: "my-full-screen-dialog",
      data: { imageUrl: data.appLogo, imageRatio: 9 / 16 },
      disableClose: true,
    });

    //lets wait dialog to close or edit image
    let newImage = await dialogRef.afterClosed().pipe(first()).toPromise();

    //if dialog not canceled
    if (newImage) {
      //now lets check if imageData is base64 or not
      if (base64MimeType(newImage)) {
        //convert base64 to File to send it to firebase as file
        const imageData: File = dataURLtoFile(newImage, "img.png");
        //lets create file list withh array of our images
        const createFileList: FileList = makeFilelist([imageData]);
        try {
          this.imageUploading = true;
          //now we are waiting to upload photo to firebase and get URL
          let newImageUrl = await this.uploadImageService
            .uploadImage(createFileList, "friendsAppps")
            .pipe(first())
            .toPromise();
          //set new URL to save in data base
          data["appLogo"] = newImageUrl.downloadURL;
        } catch (error) {
          this.imageUploading = false;
          //catch error and display it
          alert(error.error.error.message);
        }
      } else {
        //if image is old url and not base64 set appLogo to old URL
        data["appLogo"] = newImage;
      }

      //lets save incoming data
      this.tableService.SaveItem(data).subscribe(
        (row: any) => {
          this.imageUploading = false;
          this.tableService.redirect(this.url);
        },
        (error) => {
          alert(error.error.error.message);
        }
      );
    } else {
      alert("Please insert image to complete your process.");
    }
  }
}
