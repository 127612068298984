import { Component, Inject, OnInit } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataTableFormComponent } from "src/app/data-table/data-table-form/data-table-form.component";

@Component({
  selector: "app-ads-form",
  templateUrl: "./ads-form.component.html",
  styleUrls: ["./ads-form.component.scss"],
})
export class AdsFormComponent implements OnInit {
  sections = [
    { value: "global", title: "Global" },
    { value: "mosques", title: "Mosques" },
    { value: "graves", title: "Graves" },
    { value: "myPlaces", title: "My Places" },
    { value: "barid", title: "Barid" },
  ];

  constructor(
    public dialogRef: MatDialogRef<DataTableFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tableService: TableService
  ) {
    this.tableService._table = "ads";
    this.getData();
  }

  async ngOnInit() {
    if (!this.data) {
      this.data = {
        id: 0,
        title_ar: "",
        title_en: "",
        expiryDate: new Date(),
        photoUrl: "",
        placeId: 0,
        productCompanyId: 0,
        productId: 0,
        section: "global",
        status: 1,
        isFixed: false,
      };
    }
    //change data by section
    this.checkSection(this.data.section);
  }

  getData() {
    //
  }

  sectionChanged(event) {
    const section = event.value;
    this.checkSection(section);
  }

  checkSection(section) {
    console.log("section changed to", section);
    if (section == "global") {
      this.data.placeId = 0;
      this.data.productCompanyId = 0;
      this.data.productId = 0;
    }
  }

  saveData() {
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
