import { AbstractControl, ValidatorFn } from "@angular/forms";

export function NoWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      const isWhitespace = control.value.toString().trim().length === 0;
      return isWhitespace ? { whitespace: "value is only whitespace" } : null;
    }
  };
}
