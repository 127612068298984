import { Component, OnInit, Injectable } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { DataService } from "src/app/shared/services/data.service";
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { getMatScrollStrategyAlreadyAttachedError } from "@angular/cdk/overlay/typings/scroll/scroll-strategy";
import { first } from "rxjs/operators";
import { stringToJson } from "src/app/shared/helpers/customFunctions";

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  checked: boolean;
  parent: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  checked: boolean;
  parent: string;
}

const TREE_DATA = {
  Donates: {
    Create: false,
    Update: false,
    Delete: false,
  },
  Mosques: {
    Create: false,
    Update: false,
    Delete: false,
  },
  Graves: {
    Create: false,
    Update: false,
    Delete: false,
  },
  MyPlaces: {
    Create: false,
    Update: false,
    Delete: false,
  },
  SadanInfo: {
    Create: false,
    Update: false,
    Delete: false,
  },
  FAQ: {
    Create: false,
    Update: false,
    Delete: false,
  },
  Messages: {
    Create: false,
    Update: false,
    Delete: false,
  },
  Bottle_Sizes: {
    Create: false,
    Update: false,
    Delete: false,
  },
  bottelsCompany: {
    Create: false,
    Update: false,
    Delete: false,
  },
  Gov: {
    Create: false,
    Update: false,
    Delete: false,
  },
  Area: {
    Create: false,
    Update: false,
    Delete: false,
  },
  Devices: {
    Create: false,
    Update: false,
    Delete: false,
  },
};

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number, parent: string = null): TodoItemNode[] {
    const prepareData = Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;
      node.checked = value;
      node.parent = parent;

      //if (value != null) {
      if (typeof value === "object") {
        node.children = this.buildFileTree(value, level + 1, key);
      } else {
        node.item = key;
      }
      //}

      return accumulator.concat(node);
    }, []);
    return prepareData;
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ item: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: "app-roles-form",
  templateUrl: "./roles-form.component.html",
  styleUrls: ["./roles-form.component.scss"],
  providers: [ChecklistDatabase],
})
export class rolesFormComponent implements OnInit {
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  newItemName = "";
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  url: string = "roles";
  schema: any = {
    id: 0,
    name_ar: "",
    name_en: "",
    description_ar: "",
    description_en: "",
    permissions: "",
  };
  roleData: any = {
    id: 0,
    name_ar: "",
    name_en: "",
    description_ar: "",
    description_en: "",
    permissions: "",
  };
  new: boolean = true;
  checkList = [];

  constructor(
    private tableService: TableService,
    public dataService: DataService,
    private _database: ChecklistDatabase,
    private route: ActivatedRoute
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  async ngOnInit() {
    let roleId = this.route.snapshot.paramMap.get("id");
    if (roleId) {
      //if url has role id let it old data not new
      this.new = false;
      await this.getRoleData(roleId);
    }
    //reset table to roles after was roles/{id}
    this.tableService._table = "roles";
  }

  async getRoleData(roleId: string) {
    //change role url to specific role by id
    this.tableService._table = `roles/${roleId}`;
    //get role data
    let roleData: any = await this.tableService.getData().pipe(first()).toPromise();
    //if permissions is null convert it to empty array
    if (!roleData.permissions) roleData.permissions = [];
    if (roleData.permissions) roleData.permissions = stringToJson(roleData.permissions);
    //lets set roleData to response
    this.roleData = roleData;
    //now lets load checked roles in permissions list
    this.loadPermissionsArray(roleData.permissions);
    //lets update changes in material tree
    this._database.dataChange.next(this.dataSource.data);
  }

  async saveData() {
    let finalPermission: string[] = [];
    this.checklistSelection.selected.forEach((data) => {
      //change data to  lower case to save it in db
      const childToLowerCase = data.item.toLocaleLowerCase();
      const parentToLowerCase = data.parent ? data.parent.toLocaleLowerCase() : data.parent;
      if (parentToLowerCase) {
        const finalData = parentToLowerCase + "." + childToLowerCase;
        finalPermission.push(finalData);
        const parentIndex = finalPermission.findIndex((parentI) => parentI == parentToLowerCase);
        if (parentIndex == -1) finalPermission.push(parentToLowerCase);
      } else {
        const parentIndex = finalPermission.findIndex((parentI) => parentI == childToLowerCase);
        if (parentIndex == -1) finalPermission.push(childToLowerCase);
      }
    });
    //lets give permissions to roleData
    this.roleData.permissions = finalPermission.length ? `["${finalPermission.join('","')}"]` : null;
    //lets validate data
    if (this.validateData()) {
      if (this.new) {
        this.roleData["mainRole"] = 0;
      }
      this.tableService.SaveItem(this.roleData).subscribe(
        (row: any) => {
          this.dataService.notification("Saved Successfully.");
          this.tableService.redirect(this.url);
        },
        (error) => {
          alert(error.error.error.message);
        }
      );
    }
  }

  validateData(): boolean {
    let returnBoolean: boolean = true;
    const schemaKeys = Object.keys(this.schema);
    const schemaVlues = Object.values(this.schema);
    const roleData = this.roleData;
    Object.entries(roleData).forEach(([key, value]: any, index) => {
      //console.log(key,schemaKeys[index])
      const schemaIndex = schemaKeys.findIndex((data) => data == key);
      if (schemaIndex != -1) {
        if (typeof value != typeof schemaVlues[schemaIndex]) {
          //alert(`${key} must be ${schemaVlues[schemaIndex]}.`);
          this.dataService.notification(`${key} must be ${schemaVlues[schemaIndex]}.`);
        } else {
          //console.log(value,schemaKeys[schemaIndex])
          if (value.length == 0) {
            //alert(`${key} is required.`);
            this.dataService.notification(`${key} is required.`);
            returnBoolean = false;
          }
        }
      } else {
        delete roleData[key];
      }
    });
    //})
    return returnBoolean;
  }

  loadPermissionsArray(permissions: string[]) {
    for (const eachPermission of permissions) {
      const splitData = eachPermission.split("."); //split permissions to start check
      const permissionsModule = splitData[0].toString(); //get permission module
      const permissionName = splitData[1] ? splitData[1].toString() : ""; //get permission name
      const permissionIndex = this.dataSource.data.findIndex(
        (data) => data.item.toLocaleLowerCase() == permissionsModule
      ); //get index of permission module name
      if (permissionIndex !== -1) {
        //check if this permission module already exist
        const permissionNameIndex = this.dataSource.data[permissionIndex].children.findIndex(
          (data) => data.item.toLocaleLowerCase() == permissionName
        ); //Get permission name index
        if (permissionNameIndex !== -1) {
          //check if this permission alread exist
          this.dataSource.data[permissionIndex].children[permissionNameIndex].checked = true;
          const permissionNameNode = this.treeControl.dataNodes.find(
            (data) =>
              data.item.toLocaleLowerCase() == permissionName && data.parent.toLocaleLowerCase() == permissionsModule
          );
          this.todoLeafItemSelectionToggle(permissionNameNode);
        }
      }
    }
  }

  //angular material check box tree
  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === "";

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.checked = node.checked;
    flatNode.parent = node.parent;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, itemValue);
  }
}
