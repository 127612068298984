import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NoWhitespaceValidator } from "../../core/no-white-space.validator";
import { User } from "../shared/user.model";
import { UserService } from "../shared/user.service";

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styles: [],
})
export class UserEditComponent implements OnChanges {
  @Input() display = false;
  @Input() user: User;
  @Output() hide = new EventEmitter();
  userForm: FormGroup;
  notifications: any;
  photoUrl: string;

  constructor(private userService: UserService, private fb: FormBuilder) {
    this.userForm = this.fb.group(
      {
        displayName: [
          null,
          [
            Validators.required,
            NoWhitespaceValidator(),
            Validators.minLength(7),
            Validators.pattern("^([A-Z\u0621-\u064A])+[a-zA-Z\u0621-\u064A\\'\\-\\s]+$"),
          ],
        ],
        email: [
          null,
          [
            Validators.required,
            NoWhitespaceValidator(),
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ),
          ],
        ],
        password: [null, [NoWhitespaceValidator(), Validators.minLength(6)]],
      },
      { updateOn: "change" }
    );
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.user) {
      if (change.user.currentValue) {
        const userData = change.user.currentValue;
        this.userForm.patchValue({
          displayName: userData.displayName,
          email: userData.email,
          password: userData.password,
        });
      }
    }
  }

  async save(userForm: FormGroup) {
    const user = userForm.value;
    user.uid = this.user.uid;
    if (this.photoUrl) {
      user.photoUrl = this.photoUrl;
    }
    /*try {
      await this.userService.update(user).toPromise();
      this.closeDialog();
    } catch (e) {
      console.log(e);
      this.displayErrorMessage();
    }*/
  }

  onUploadComplete(e) {
    this.photoUrl = e;
  }

  closeDialog() {
    this.hide.emit(true);
  }

  displayErrorMessage() {
    this.notifications = [];
    const message = "Error";
    const summary = "Error";
    this.notifications.push({ severity: "error", summary: summary, detail: message });
  }

  get displayName() {
    return this.userForm.get("displayName");
  }

  get email() {
    return this.userForm.get("email");
  }

  get password() {
    return this.userForm.get("password");
  }
}
