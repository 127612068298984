import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MailService } from "src/app/shared/services/mail.service";
import { TableService } from "src/app/core/data/table.service";
import { first } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { DeleteConfirmDialog } from "src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component";
import { MatDialog } from "@angular/material";
import { AuthService } from "src/app/core/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-messages-body",
  templateUrl: "./messages-body.component.html",
  styleUrls: ["./messages-body.component.scss"],
})
export class messagesBodyComponent implements OnInit {
  @Input() "messageInfo";
  messageBody;
  moduleName = "messages";
  _url = "messages";

  constructor(
    private tableService: TableService,
    private dialog: MatDialog,
    public dataService: DataService,
    public authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  async sendMessage() {
    if (this.messageBody) {
      //lets send reply to db
      this.tableService._table = "contact-us-reply";
      try {
        const newMessage = await this.tableService
          .SaveItem({
            fullName: this.dataService.currentUser.username,
            email: this.dataService.currentUser.email,
            message: this.messageBody,
            userId: this.dataService.currentUser.id,
            messageId: this.messageInfo.id,
          })
          .toPromise();
        if (!this.messageInfo.hasOwnProperty("replies")) this.messageInfo["replies"] = [];
        this.messageInfo.replies.push(newMessage);
      } catch (error) {
        let message = "";
        if (this.translate.currentLang == "ar") message = "حدث خطأ اثناء الارسال";
        if (this.translate.currentLang == "en") message = "Something went wronge while sending message";
        this.dataService.notification(message);
        return console.error(error);
      }
      //lets send email for user
      this.tableService._table = "contact-us";
      var mailData = {
        userEmail: this.messageInfo.email,
        emailBody: this.messageBody,
      };
      try {
        let response: any = await this.tableService.http
          .post(this.tableService._api + "contact-us/send-mail", mailData, this.tableService.Headers())
          .pipe(first())
          .toPromise();
        this.dataService.notification(response.message);
      } catch (error) {
        alert(error.error.error.message);
      }
      //this.mailService.sendMessage(this.messageInfo.email,'Sadan | Reply',this.messageBody)
      //console.log(this.messageBody,this.messageInfo)
    }
  }

  async deleteMsg(msgId) {
    const dialogRef = this.dialog.open(DeleteConfirmDialog, {
      width: "250px",
      data: { password: "" },
      disableClose: true,
    });

    let confirmDelete = await dialogRef.afterClosed().pipe(first()).toPromise();

    if (confirmDelete) {
      this.tableService.Remove({ id: msgId }).subscribe((row: any) => {
        this.tableService.redirect(this._url);
      });
    }
  }
}
