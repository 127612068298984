import { Injectable, EventEmitter } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  event = new EventEmitter<any>();

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    //return true;
    if (localStorage.User) {
      const mainUrl = next.url[0].path;
      const userRoles = JSON.parse(localStorage.User).roles;
      const adminsArray = ["admin", "superAdmin"];
      if (
        !userRoles.includes(mainUrl.toLowerCase()) &&
        mainUrl.toLowerCase() != "home" &&
        !userRoles.some((roles) => adminsArray.includes(roles))
      ) {
        this.router.navigate(["/home"]);
        return false;
      }
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
    /*return this.auth.user
      .pipe(
        take(1),
        map(user => {
          if(user){
            if (next.data.roles && next.data.roles.search(user.role.toLowerCase()) === -1){
              this.router.navigate(['/hackathons']);
              return false;
            }
            return true;
          }
        })
        ,
        tap(loggedIn => {
          if (!loggedIn) {
            this.router.navigate(['/login']);
          }
        })
      );*/
  }
}
