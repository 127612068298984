import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserService {
  //private url: string = environment.serverURL;
  /*private headers: HttpHeaders;*/

  constructor(private http: HttpClient) {
    /*this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json');*/
  }

  clean(obj) {
    for (const prop in obj) {
      if (!obj[prop]) {
        delete obj[prop];
      }
    }
  }
}
