import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { User } from "../users/shared/user.model";
import { HttpClient } from "@angular/common/http";
import { TableService } from "./data/table.service";
import { DataService } from "../shared/services/data.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user: Observable<User>;
  data: string;
  //currentUser = JSON.parse(localStorage.getItem('User'));

  constructor(
    private router: Router,
    private http: HttpClient,
    private table: TableService,
    private dataService: DataService
  ) {
    /*this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {//typeof roleCanLog !== 'undefined' || typeof roleCanLog !== undefined || roleCanLog === newData.role
          this.setUserInfo()
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );*/
    // this.user.subscribe(user => console.log(user));
  }

  setUserInfo() {
    /*const userId = this.afAuth.auth.currentUser.uid
    //this.afAuth.idToken.subscribe(data => console.log(data))
    return this.afs.doc<User>(`users/${userId}`).valueChanges().subscribe(res => {
      this.userDetails = res;
    })*/
  }

  LogIn(obj) {
    return this.http.post(this.table._api + "admin/login", obj);
  }

  getDetails(id) {
    return this.http.get(this.table._api + 'users/me?filter={"include":["roles"]}', this.table.Headers());
  }

  canUpdate(moduleName): boolean {
    return this.checkPermissions(moduleName, "update");
  }

  canDelete(moduleName): boolean {
    return this.checkPermissions(moduleName, "delete");
  }

  canCreate(moduleName): boolean {
    return this.checkPermissions(moduleName, "create");
  }

  //check permissions
  checkPermissions(moduleName: String, type: String): boolean {
    //if(!admin) return false;
    const adminsArray = ["admin", "superAdmin"];
    if (
      this.dataService.currentUser.hasOwnProperty("roles") &&
      this.dataService.currentUser.roles.some((roles) => adminsArray.includes(roles))
    )
      return true;
    const permissionType: string = (moduleName.toLowerCase() + "." + type.toLowerCase()).toString();
    if (this.dataService.currentUser.hasOwnProperty("roles"))
      return this.dataService.currentUser.roles.includes(permissionType);
    return false;
  }

  //check if user have permission for something
  userHavePermission(permission: string) {
    if (this.dataService.currentUser.hasOwnProperty("roles"))
      return this.dataService.currentUser.roles.includes(permission);
    return false;
  }
}
