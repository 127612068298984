import { Component, OnInit } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { ActivatedRoute } from "@angular/router";
import { first, map } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { stringToJson, makeFilelist } from "src/app/shared/helpers/customFunctions";
import { MatDialog } from "@angular/material/dialog";
import { ImageDialog } from "src/app/shared/image-dialog/image-dialog.component";
import { formatDate } from "src/app/shared/helpers/convertDate";
import { isImage, base64MimeType, dataURLtoFile } from "src/app/shared/helpers/checkFileType";
import { UploadImage } from "src/app/shared/services/imageUpload.service";
import { rolesId } from "src/app/shared/models/interfaces";
import { TranslateService } from "@ngx-translate/core";
import { ImageEdit } from "src/app/shared/image-edit/image-edit.component";

@Component({
  selector: "app-company-form",
  templateUrl: "./company-form.component.html",
  styleUrls: ["./company-form.component.scss"],
})
export class companyFormComponent implements OnInit {
  url: string = "bottelsCompany";
  pageLoading = false;
  areas = [];

  companyData = {
    id: 0,
    order: 0,
    name: "",
    logo: "",
    status: 1,
  };

  limits = {
    minlimit: null,
    maxlimit: null,
  };

  constructor(
    private tableService: TableService,
    private route: ActivatedRoute,
    public dataService: DataService,
    private dialog: MatDialog,
    private uploadImageService: UploadImage,
    public translate: TranslateService
  ) {}

  async ngOnInit() {
    let companyId = this.route.snapshot.paramMap.get("id");
    this.companyData.id = Number(companyId);
    await this.getAreasData();
    if (companyId) {
      await this.getCompanyData();
      this.tableService._table = "bottles-companies";
    }
  }

  async getCompanyData() {
    this.tableService._table = "bottles-companies/" + this.companyData.id;
    let company: any = await this.tableService.getData().toPromise();
    this.companyData = company;
  }

  async getAreasData() {
    var findWhere = `{"fields":{"id":true,"zone_name_ar":true,"zone_name_en":true},"include":[{"relation":"companyLimit","scope":{"where":{"companyId":"${this.companyData.id}"}}}]}`;
    this.tableService._table = "zones";
    let areas: any = await this.tableService
      .getData(findWhere)
      .pipe(
        map((areasData: any) => {
          return areasData.map((eachArea) => {
            //lets create default min & max limits
            let minLimit = 1;
            let maxLimit = 100;
            //if area has
            if (eachArea.hasOwnProperty("companyLimit")) {
              minLimit = eachArea.companyLimit.minLimit;
              maxLimit = eachArea.companyLimit.maxLimit;
            }
            return { minLimit, maxLimit, ...eachArea };
          });
        })
      )
      .toPromise();
    this.areas = areas;
  }

  async saveData() {
    //lets update companies
    try {
      //save company data
      this.tableService._table = `bottles-companies`;
      const companyData = await this.tableService.SaveItem(this.companyData).pipe(first()).toPromise();
      if (companyData) {
        this.companyData.id = companyData["id"];
      }
      //lets save companies areas data
      this.tableService._table = `bottles-companies-areas`;
      let areasData = [];
      this.areas.forEach((eachArea) => {
        areasData.push({
          companyId: Number(this.companyData.id),
          areaId: Number(eachArea.id),
          maxLimit: Number(eachArea.maxLimit),
          minLimit: Number(eachArea.minLimit),
        });
      });
      await this.tableService.SaveItem(areasData).pipe(first()).toPromise();
      this.dataService.notification("Saved Successfully.");
      this.tableService.redirect(this.url);
    } catch (error) {
      alert(error.error.error.message);
    }
  }

  changeInputs() {
    this.areas.forEach((each, index) => {
      if (this.limits.minlimit) this.areas[index]["minLimit"] = this.limits.minlimit;
      if (this.limits.maxlimit) this.areas[index]["maxLimit"] = this.limits.maxlimit;
    });
  }

  async setSelectedPhoto() {
    //get image and give it to ImageEdit Dialog
    const dialogRef = this.dialog.open(ImageEdit, {
      panelClass: "my-full-screen-dialog",
      data: { imageUrl: event },
      disableClose: true,
    });

    //lets wait dialog to close or edit image
    let newImage = await dialogRef.afterClosed().pipe(first()).toPromise();

    //if dialog not canceled
    if (newImage) {
      //now lets check if imageData is base64 or not
      if (base64MimeType(newImage)) {
        //convert base64 to File to send it to firebase as file
        const imageData: File = dataURLtoFile(newImage, "img.png");
        //lets create file list withh array of our images
        const createFileList: FileList = makeFilelist([imageData]);
        try {
          this.pageLoading = true;
          //now we are waiting to upload photo to firebase and get URL
          let newImageUrl = await this.uploadImageService
            .uploadImage(createFileList, "bottlesCompanies")
            .pipe(first())
            .toPromise();
          //set new URL to save in data base
          this.companyData["logo"] = newImageUrl.downloadURL;
        } catch (error) {
          this.pageLoading = false;
          //catch error and display it
          alert(error.error.error.message);
        }
      } else {
        //if image is old url and not base64 set photoUrl to old URL
        this.companyData["logo"] = newImage;
      }
      this.pageLoading = false;
    } else {
      alert("Please insert image to complete your process.");
    }
  }
}
