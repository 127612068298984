import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { first } from "rxjs/operators";
import { formatDate } from "src/app/shared/helpers/convertDate";
import { AuthService } from "src/app/core/auth.service";

@Component({
  selector: "app-messages-list",
  templateUrl: "./messages-list.component.html",
  styleUrls: ["./messages-list.component.scss"],
})
export class messagesListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ["message"];
  moduleName = "messages";
  url: string = "messages";

  dataSource: MatTableDataSource<any>;

  selectedMessage;

  constructor(private tableService: TableService, public authService: AuthService) {
    this.tableService._table = "contact-us";
    this.getData();
  }

  async ngOnInit() {
    /*const mesgsData:any = await this.tableService.getData().pipe(first()).toPromise();
    this.dataSource = new MatTableDataSource(mesgsData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;*/
  }

  async getData() {
    const where = `{"order":"id desc","include":[{"relation":"replies"}]}`;
    const msgsData: any = await this.tableService.getData(where).pipe(first()).toPromise();
    this.dataSource = new MatTableDataSource(msgsData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getDataForAction(data) {
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }

  setMessage(message) {
    this.selectedMessage = message;
  }

  formatDate(date) {
    return formatDate(date);
  }
}
