import { NgModule } from "@angular/core";
import { placesReportsViewComponent } from "./places-reports-view/places-reports-view.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { SharedModule } from "src/app/shared/shared.module";
import { DataTableModule } from "src/app/data-table/data-table.module";
import { TableService } from "src/app/core/data/table.service";
import { MatToolbarModule, MatDatepickerModule, MatDividerModule } from "@angular/material";
import { TranslateModule } from "@ngx-translate/core";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatGridListModule } from "@angular/material/grid-list";

@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    DataTableModule,
    TranslateModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatDividerModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatGridListModule,
  ],
  declarations: [placesReportsViewComponent],
  providers: [TableService],
  exports: [placesReportsViewComponent],
  entryComponents: [],
})
export class placesReportsModule {}
