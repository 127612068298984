import { NgModule } from "@angular/core";
import { companyListComponent } from "./company-list/company-list.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { SharedModule } from "src/app/shared/shared.module";
import { DataTableModule } from "src/app/data-table/data-table.module";
import { TableService } from "src/app/core/data/table.service";
import { ImageEdit } from "src/app/shared/image-edit/image-edit.component";
import {
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatGridListModule,
  MatSelectModule,
  MatDividerModule,
  MatListModule,
} from "@angular/material";
import { TranslateModule } from "@ngx-translate/core";
import { companyFormComponent } from "./company-form/company-form.component";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatProgressSpinnerModule,
    DataTableModule,
    TranslateModule,
    MatToolbarModule,
    FormsModule,
    MatGridListModule,
    MatSelectModule,
    MatDividerModule,
    MatListModule,
    RouterModule,
  ],
  declarations: [companyListComponent, companyFormComponent],
  providers: [TableService],
  exports: [companyListComponent],
  entryComponents: [ImageEdit],
})
export class companyModule {}
