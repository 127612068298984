import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/core/data/table.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/core/auth.service";

@Component({
  selector: "app-gov-list",
  templateUrl: "./gov-list.component.html",
  styleUrls: ["./gov-list.component.scss"],
})
export class govListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleName = "gov";
  url: string = "gov";

  displayedColumns = [
    {
      title: "ID",
      id: "id",
      type: "number",
      editable: false,
    },
    {
      title: "Name ar",
      id: "governates_name_ar",
      type: "text",
      editable: true,
    },
    {
      title: "Name en",
      id: "governates_name_en",
      type: "text",
      editable: true,
    },
    {
      title: "Governate Location Lng",
      id: "govLng",
      type: "number",
      editable: true,
    },
    {
      title: "Governate Location Lat",
      id: "govLat",
      type: "number",
      editable: true,
    },
    {
      title: "Status",
      id: "status",
      type: "booleanNumber",
      editable: true,
    },
  ];
  dataSource: MatTableDataSource<any>;

  constructor(private tableService: TableService, public authService: AuthService) {
    this.tableService._table = "govs";
    this.getData();
  }

  async ngOnInit() {
    //this.dataSource = await this.tableService.getData().pipe(first()).toPromise();
  }

  getData() {
    this.tableService
      .getData('{"order":"id desc"}')
      .pipe(
        map((data: any) => {
          return data.map((govData: any) => {
            let govLat = "";
            let govLng = "";
            //if govLocation not empty
            if (govData.govLocation) {
              //get city location data and create new objects for lat & lng
              govLat = govData["govLocation"].hasOwnProperty("y") ? govData["govLocation"].y : "";
              govLng = govData["govLocation"].hasOwnProperty("x") ? govData["govLocation"].x : "";
            }
            return { govLat, govLng, ...govData };
          });
        })
      )
      .subscribe((data: any) => {
        this.dataSource = data;
      });
  }

  getDataForAction(data) {
    data["govLocation"] = { x: data["govLng"], y: data["govLat"] }; //`PointFromText(CONCAT('POINT(',${data['govLng']},' ',${data['govLat']},')'),1)`//`POINT(${data['govLng']}, ${data['govLat']})`;
    delete data["govLat"];
    delete data["govLng"];
    if (data["id"] == 0) {
      //if new gov
      data["country_id"] = 1; //set country id with 1
    }
    this.tableService.SaveItem(data).subscribe(
      (row: any) => {
        this.tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
    //console.log('from main ',data)
  }
}
