import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "../auth/login/login.component";
import { LogoutComponent } from "../auth/logout/logout.component";
import { HomeComponent } from "../home/home/home.component";
import { AuthGuard } from "./auth.guard";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { UserListComponent } from "../users/user-list/user-list.component";
import { DonatesListComponent } from "../donates/donates-list/donates-list.component";
import { mosquesListComponent } from "../mosques/mosques-list/mosques-list.component";
import { MedicalFacilitiesComponent } from "../medicalFacilities/medical-facilities/medical-facilities.component";
import { MedicalFacilitiesFormComponent } from "../medicalFacilities/medical-facilities-form/medical-facilities-form.component";
import { gravesListComponent } from "../graves/graves-list/graves-list.component";
import { placeTypesListComponent } from "../settings/placeTypes/placeTypes-list/placeTypes-list.component";
import { bottle_sizesListComponent } from "../settings/bottle_sizes/bottle_sizes-list/bottle_sizes-list.component";
import { rolesListComponent } from "../settings/roles/roles-list/roles-list.component";
import { devicesListComponent } from "../reports/devices/devices-list/devices-list.component";
import { messagesListComponent } from "../messages/messages-list/messages-list.component";
import { faqListComponent } from "../faq/faq-list/faq-list.component";
import { mosquesFormComponent } from "../mosques/mosques-form/mosques-form.component";
import { gravesFormComponent } from "../graves/graves-form/graves-form.component";
import { donatesFormComponent } from "../donates/donates-form/donates-form.component";
import { govListComponent } from "../settings/gov/gov-list/gov-list.component";
import { areaListComponent } from "../settings/area/area-list/area-list.component";
import { rolesFormComponent } from "../settings/roles/roles-form/roles-form.component";
import { companyDonatesListComponent } from "../company/company-donates/company-donates-list/company-donates-list.component";
import { companyDonatesFormComponent } from "../company/company-donates/company-donates-form/company-donates-form.component";
import { CompanyUserListComponent } from "../company/company-users/company-user-list/company-user-list.component";
import { sadnInfoListComponent } from "../settings/sadnInfo/sadnInfo-list/sadnInfo-list.component";
import { paymentsListComponent } from "../reports/payments/payments-list/payments-list.component";
import { myPlacesListComponent } from "../myPlaces/myPlaces-list/myPlaces-list.component";
import { baridListComponent } from "../barid/barid-list/barid-list.component";
import { baridFormComponent } from "../barid/barid-form/barid-form.component";
import { placesReportsViewComponent } from "../reports/places-reports/places-reports-view/places-reports-view.component";
import { companyListComponent } from "../settings/company/company-list/company-list.component";
import { companyFormComponent } from "../settings/company/company-form/company-form.component";
import { quickDonatesListComponent } from "../quickDonates/quickDonates-list/quickDonates-list.component";
import { quickDonatesFormComponent } from "../quickDonates/quickDonates-form/quickDonates-form.component";
import { AdsListComponent } from "../settings/ads/ads-list/ads-list.component";
import { BottleSectionsListComponent } from "../settings/bottle_sections/bottle_sections-list/bottle_sections-list.component";
import { BaridChiratiesListComponent } from "../settings/barid_chiraties/barid_chiraties-list/barid_chiraties-list.component";
import { OffersListComponent } from "../settings/offers/offers-list/offers-list.component";
import { FriendsAppsListComponent } from "../settings/friendsApps/friends-apps-list/friends-apps-list.component";
import { DoneRequestsFormComponent } from "../settings/doneRequests/done-requests-form/done-requests-form.component";
import { DoneRequestsListComponent } from "../settings/doneRequests/done-requests-list/done-requests-list.component";

const coreRoutes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  //{path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "users", component: UserListComponent, canActivate: [AuthGuard] },
  { path: "donates/:status", component: DonatesListComponent, canActivate: [AuthGuard] },
  { path: "donates/form/:id", component: donatesFormComponent, canActivate: [AuthGuard] },
  { path: "barid/:status", component: baridListComponent, canActivate: [AuthGuard] },
  { path: "barid/form/:id", component: baridFormComponent, canActivate: [AuthGuard] },
  { path: "mosques", component: mosquesListComponent, canActivate: [AuthGuard] },
  { path: "mosques/form", component: mosquesFormComponent, canActivate: [AuthGuard] },
  { path: "mosques/form/:id", component: mosquesFormComponent, canActivate: [AuthGuard] },
  { path: "medicalFacilities", component: MedicalFacilitiesComponent, canActivate: [AuthGuard] },
  { path: "medicalFacilities/form", component: MedicalFacilitiesFormComponent, canActivate: [AuthGuard] },
  { path: "medicalFacilities/form/:id", component: MedicalFacilitiesFormComponent, canActivate: [AuthGuard] },
  { path: "graves", component: gravesListComponent, canActivate: [AuthGuard] },
  { path: "graves/form", component: gravesFormComponent, canActivate: [AuthGuard] },
  { path: "graves/form/:id", component: gravesFormComponent, canActivate: [AuthGuard] },
  { path: "myPlaces", component: myPlacesListComponent, canActivate: [AuthGuard] },
  { path: "place_types", component: placeTypesListComponent, canActivate: [AuthGuard] },
  { path: "bottle_sizes", component: bottle_sizesListComponent, canActivate: [AuthGuard] },
  { path: "friendsApps", component: FriendsAppsListComponent, canActivate: [AuthGuard] },
  { path: "bottle_sections", component: BottleSectionsListComponent, canActivate: [AuthGuard] },
  { path: "offers", component: OffersListComponent, canActivate: [AuthGuard] },
  { path: "gov", component: govListComponent, canActivate: [AuthGuard] },
  { path: "area", component: areaListComponent, canActivate: [AuthGuard] },
  { path: "roles", component: rolesListComponent, canActivate: [AuthGuard] },
  { path: "roles/form", component: rolesFormComponent, canActivate: [AuthGuard] },
  { path: "roles/form/:id", component: rolesFormComponent, canActivate: [AuthGuard] },
  { path: "devices", component: devicesListComponent, canActivate: [AuthGuard] },
  { path: "payments", component: paymentsListComponent, canActivate: [AuthGuard] },
  { path: "messages", component: messagesListComponent, canActivate: [AuthGuard] },
  { path: "faq", component: faqListComponent, canActivate: [AuthGuard] },
  { path: "sadanInfo", component: sadnInfoListComponent, canActivate: [AuthGuard] },
  { path: "company/donates/:status", component: companyDonatesListComponent, canActivate: [AuthGuard] },
  { path: "company/donates/form/:id", component: companyDonatesFormComponent, canActivate: [AuthGuard] },
  { path: "company/deliveries", component: CompanyUserListComponent, canActivate: [AuthGuard] },
  { path: "reports/places", component: placesReportsViewComponent, canActivate: [AuthGuard] },
  { path: "bottelsCompany", component: companyListComponent, canActivate: [AuthGuard] },
  { path: "bottelsCompany/form", component: companyFormComponent, canActivate: [AuthGuard] },
  { path: "bottelsCompany/form/:id", component: companyFormComponent, canActivate: [AuthGuard] },
  { path: "quickDonates/:status", component: quickDonatesListComponent, canActivate: [AuthGuard] },
  { path: "quickDonates", component: quickDonatesListComponent, canActivate: [AuthGuard] },
  { path: "quickDonates/form/:id", component: quickDonatesFormComponent, canActivate: [AuthGuard] },
  { path: "ads", component: AdsListComponent, canActivate: [AuthGuard] },
  { path: "mosques-done-requests", component: DoneRequestsListComponent, canActivate: [AuthGuard] },
  { path: "medicalFacilities-done-requests", component: DoneRequestsListComponent, canActivate: [AuthGuard] },
  { path: "mosques-done-requests/form/:id", component: DoneRequestsFormComponent, canActivate: [AuthGuard] },
  { path: "medicalFacilities-done-requests/form/:id", component: DoneRequestsFormComponent, canActivate: [AuthGuard] },
  { path: "barid-chiraties", component: BaridChiratiesListComponent, canActivate: [AuthGuard] },
  { path: "**", component: PageNotFoundComponent },
];

export const CoreRouting: ModuleWithProviders = RouterModule.forChild(coreRoutes);
